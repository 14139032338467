import axios from "axios";
import CryptoJS from "crypto-js";

const MemberAPI = axios.create({
  baseURL: process.env.VUE_APP_MemberAPI,
  headers: { "Content-Type": "application/json" },
});
const CalculateAPI = axios.create({
  baseURL: process.env.VUE_APP_CalculateAPI,
  headers: { "Content-Type": "application/json" },
});
const GatewayAPI = axios.create({
  baseURL: process.env.VUE_APP_GatewayAPI,
  headers: { "Content-Type": "application/json" },
});
const ProductAPI = axios.create({
  baseURL: process.env.VUE_APP_ProductAPI,
  headers: { "Content-Type": "application/json" },
});
const key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_KEY);
const iv = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_IV);
const Encrypt = (word) => {
  if (word == "undefined") {
    return ErrorHandle("Login", {
      Method: "POST",
      Url: "Member/Login",
      Msg: "平台ID獲取失敗(undefined)",
      StatusCode: "",
    });
  }
  let srcs = CryptoJS.enc.Utf8.parse(word);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.ciphertext.toString().toUpperCase();
};
//解密
const Decrypt = (word) => {
  let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
  let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  let decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
};
const ErrorHandle = (error, data, forceLogout = true) => {
  let url = process.env.VUE_APP_GatewayAPI + "/systems/error_log";
  window.localStorage.removeItem("login_status");
  window.localStorage.removeItem("AC");
  window.localStorage.removeItem("imgUrl");
  window.localStorage.removeItem("haveNoData");
  window.localStorage.removeItem("token");
  window.localStorage.removeItem("LT");
  window.localStorage.setItem("errorTime", new Date());
  window.localStorage.setItem("status", error);
  if (process.env.NODE_ENV == "dev" || process.env.NODE_ENV == "local") {
    alert(`後端請求錯誤! [這個訊息僅在開發環境顯示，為了方便除錯而已，正式站台並不會出現此訊息，請重整後重新登入並繼續使用，或是紀錄剛剛的操作流程並提交開發人員]`);
  }
  if (data) {
    let Arg = {
      MemberId: parseInt(window.localStorage.getItem("loginIndex")),
      PlatformId: window.localStorage.getItem("before"),
      Method: data.Method,
      Url: data.Url,
      Msg: JSON.stringify(data.Msg),
      FromNode: localStorage.getItem("source") || "Website",
      StatusCode: data.Msg.message,
    };
    axios
      .post(url, Arg)
      .then((data) => {
        if (forceLogout) {
          if (process.env.NODE_ENV != "dev" && process.env.NODE_ENV != "local") {
            window.location.reload();
          }
        }
      })
      .catch((error) => {
        if (forceLogout) {
          if (process.env.NODE_ENV != "dev" && process.env.NODE_ENV != "local") {
            window.location.reload();
          }
        }
      });
  } else {
    if (forceLogout) {
      if (process.env.NODE_ENV != "dev" && process.env.NODE_ENV != "local") {
        window.location.reload();
      }
    }
  }
};

let isAlreadyTimeout = false;
const TimeoutHandler = (Url, Method = "post") => {
  if (!isAlreadyTimeout) {
    alert("很抱歉，當前網路不穩定或是服務資源擴增中，請稍後再試！");
    isAlreadyTimeout = true;
  }
  setTimeout(() => {
    isAlreadyTimeout = false;
  }, 5000);
  ErrorHandle("SendReport error", {
    Method,
    Url,
    Msg: {
      Msg: "30s timeout",
      message: "408",
    },
  });
};

export default {
  MemberAPI,
  CalculateAPI,
  GatewayAPI,
  ProductAPI,
  Encrypt,
  Decrypt,
  ErrorHandle,
  TimeoutHandler,
};
