import { render, staticRenderFns } from "./NavBar.vue?vue&type=template&id=4295d220&scoped=true&"
import script from "./NavBar.vue?vue&type=script&lang=js&"
export * from "./NavBar.vue?vue&type=script&lang=js&"
import style0 from "./NavBar.vue?vue&type=style&index=0&id=4295d220&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4295d220",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VSlideYReverseTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VAvatar,VSlideYReverseTransition})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\40420\\OneDrive\\桌面\\星學會\\DaMou-Frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4295d220')) {
      api.createRecord('4295d220', component.options)
    } else {
      api.reload('4295d220', component.options)
    }
    module.hot.accept("./NavBar.vue?vue&type=template&id=4295d220&scoped=true&", function () {
      api.rerender('4295d220', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/NavBar.vue"
export default component.exports