var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "buyDBL" } },
    [
      _c("div", { staticClass: "header" }, [
        _vm._m(0),
        _c(
          "div",
          {
            staticClass: "header__addFriendBtn hidden-pad-down",
            on: {
              click: function($event) {
                return _vm.addFriend()
              }
            }
          },
          [
            _c("img", {
              attrs: {
                src: require("../../assets/占星小鋪/add friend.svg"),
                alt: ""
              }
            })
          ]
        )
      ]),
      _c("div", { staticClass: "buyDBL__main" }, [
        _c("h3", [_vm._v("選擇合盤類型")]),
        _c("h4", [_vm._v("每次購買只能買一種合盤類型喔")]),
        _c("div", { staticClass: "buyDBL__main__typeGroup" }, [
          _c(
            "label",
            {
              staticClass: "buyDBL__main__typeGroup__item",
              class: { active: _vm.typeSelect.couple }
            },
            [
              _c("div", { staticClass: "checkbox-wrap" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.typeSelect.couple,
                      expression: "typeSelect.couple"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.typeSelect.couple)
                      ? _vm._i(_vm.typeSelect.couple, null) > -1
                      : _vm.typeSelect.couple
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.typeSelect.couple,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.typeSelect,
                              "couple",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.typeSelect,
                              "couple",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.typeSelect, "couple", $$c)
                      }
                    }
                  }
                }),
                _c("span", { staticClass: "checkmark" })
              ]),
              _vm._m(1),
              _c("div", { staticClass: "buyDBL__main__typeGroup__title" }, [
                _vm._v("感情")
              ])
            ]
          ),
          _c(
            "label",
            {
              staticClass: "buyDBL__main__typeGroup__item",
              class: { active: _vm.typeSelect.friend }
            },
            [
              _c("div", { staticClass: "checkbox-wrap" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.typeSelect.friend,
                      expression: "typeSelect.friend"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.typeSelect.friend)
                      ? _vm._i(_vm.typeSelect.friend, null) > -1
                      : _vm.typeSelect.friend
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.typeSelect.friend,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.typeSelect,
                              "friend",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.typeSelect,
                              "friend",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.typeSelect, "friend", $$c)
                      }
                    }
                  }
                }),
                _c("span", { staticClass: "checkmark" })
              ]),
              _vm._m(2),
              _c("div", { staticClass: "buyDBL__main__typeGroup__title" }, [
                _vm._v("朋友")
              ])
            ]
          ),
          _c(
            "label",
            {
              staticClass: "buyDBL__main__typeGroup__item",
              class: { active: _vm.typeSelect.workplace }
            },
            [
              _c("div", { staticClass: "checkbox-wrap" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.typeSelect.workplace,
                      expression: "typeSelect.workplace"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.typeSelect.workplace)
                      ? _vm._i(_vm.typeSelect.workplace, null) > -1
                      : _vm.typeSelect.workplace
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.typeSelect.workplace,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.typeSelect,
                              "workplace",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.typeSelect,
                              "workplace",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.typeSelect, "workplace", $$c)
                      }
                    }
                  }
                }),
                _c("span", { staticClass: "checkmark" })
              ]),
              _vm._m(3),
              _c("div", { staticClass: "buyDBL__main__typeGroup__title" }, [
                _vm._v("職場")
              ])
            ]
          ),
          _c(
            "label",
            {
              staticClass: "buyDBL__main__typeGroup__item",
              class: { active: _vm.typeSelect.parentChild }
            },
            [
              _c("div", { staticClass: "checkbox-wrap" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.typeSelect.parentChild,
                      expression: "typeSelect.parentChild"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.typeSelect.parentChild)
                      ? _vm._i(_vm.typeSelect.parentChild, null) > -1
                      : _vm.typeSelect.parentChild
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.typeSelect.parentChild,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.typeSelect,
                              "parentChild",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.typeSelect,
                              "parentChild",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.typeSelect, "parentChild", $$c)
                      }
                    }
                  }
                }),
                _c("span", { staticClass: "checkmark" })
              ]),
              _vm._m(4),
              _c("div", { staticClass: "buyDBL__main__typeGroup__title" }, [
                _vm._v("親子")
              ])
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "buyDBL__main" }, [
        _c("h3", [_vm._v("設定合盤組合")]),
        _c("h4", [_vm._v("您可以一次購買多個合盤組合喔")]),
        _c("div", { staticClass: "select__show" }, [
          _c(
            "div",
            {
              staticClass: "select__show__item",
              on: {
                click: function($event) {
                  _vm.showSelectFriendsDialog = true
                }
              }
            },
            [
              _vm.select_A
                ? _c("div", { staticClass: "select__show__item__text" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.select_A.Name) + "\n        "
                    )
                  ])
                : _c("div", { staticClass: "select__show__item__text" }, [
                    _vm._v("選擇好友")
                  ]),
              _c("img", {
                attrs: { src: require("@/assets/icon_downward.svg") }
              })
            ]
          ),
          _vm._m(5),
          _c(
            "div",
            {
              staticClass: "select__show__item",
              on: {
                click: function($event) {
                  _vm.showSelectFriendsDialog = true
                }
              }
            },
            [
              _vm.select_B
                ? _c("div", { staticClass: "select__show__item__text" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.select_B.Name) + "\n        "
                    )
                  ])
                : _c("div", { staticClass: "select__show__item__text" }, [
                    _vm._v("選擇好友")
                  ]),
              _c("img", {
                attrs: { src: require("@/assets/icon_downward.svg") }
              })
            ]
          )
        ])
      ]),
      _vm._m(6),
      _vm.showSelectFriendsDialog
        ? _c("SelectFriendsDialog", {
            attrs: { friendsList: _vm.List_all },
            on: {
              toSelect: function(ref) {
                var item = ref.item
                var is_checked = ref.is_checked

                return _vm.selectHandler(item, is_checked)
              },
              cancel: function($event) {
                _vm.showSelectFriendsDialog = false
              }
            }
          })
        : _vm._e(),
      _vm.showCreateFriendDialog
        ? _c("CreateFriendDialog", {
            on: {
              success: _vm.createFriendSuccess,
              cancel: _vm.createFriednCancel
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "st_title" }, [
      _c("img", {
        attrs: { src: require("@/assets/占星小鋪/friend.svg"), alt: "" }
      }),
      _vm._v("\n      合盤設定\n    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "buyDBL__main__typeGroup__item__pic" }, [
      _c("img", {
        attrs: { src: require("@/assets/雙人合盤/情侶.svg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "buyDBL__main__typeGroup__item__pic" }, [
      _c("img", {
        attrs: { src: require("@/assets/雙人合盤/情侶.svg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "buyDBL__main__typeGroup__item__pic" }, [
      _c("img", {
        attrs: { src: require("@/assets/雙人合盤/職場.svg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "buyDBL__main__typeGroup__item__pic" }, [
      _c("img", {
        attrs: { src: require("@/assets/雙人合盤/親子.svg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "select__show__pic" }, [
      _c("img", {
        attrs: { src: require("@/assets/雙人合盤/好友分隔.svg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "buyDBL__main" }, [
      _c("h3", [_vm._v("訂單總計")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }