<template>
  <div class="popup-mask">
    <div class="popup-mask-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "FixedPopup",
};
</script>

<style lang="scss">
@import "@/scss/_mixin.scss";

body,
html {
  &:has(.popup-mask:not([style*="display: none"])) {
    overflow: hidden;
  }
}

.popup-mask {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;

  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  overflow: auto;
  overflow: overlay;
  &.onFocus {
    // @include mobile {
    //  align-items: flex-start !important;
    // }
  }
  &-container {
    padding: 20px 0;
    min-height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
