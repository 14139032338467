import Base from "@/api/_base";
import store from "@/store";

let { MemberAPI, Encrypt, ErrorHandle, TimeoutHandler } = Base;
let baseConfig = {
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30000,
};
let urlencoded_config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  timeout: 30000,
};
let timer = {};
MemberAPI.interceptors.request.use(
  function (config) {
    if (timer[config.url]) {
      clearTimeout(timer[config.url]);
    }
    timer[config.url] = setTimeout(() => {
      TimeoutHandler(config.url, config.method);
    }, 30000);
    if (config.closeLoadAnimation) {
    } else {
      store.commit("setLoading", true);
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
MemberAPI.interceptors.response.use(
  function (response) {
    clearTimeout(timer[response.config.url]);
    store.commit("setLoading", false);
    return response;
  },
  function (error) {
    // console.log("error", error);
    clearTimeout(timer[error.config.url]);
    store.commit("setLoading", false);
    return Promise.reject(error);
  }
);
export default {
  //----------------基本------------------
  //會員登入
  Login: async (DESed) => {
    let Arg = {
      EncryptPlatformId: DESed,
      FromNode: localStorage.getItem("source") || "Website",
    };
    try {
      let { data } = await MemberAPI.post("/members/login", Arg);
      return data;
    } catch (err) {
      alert("登入失敗，請稍後再試");
      clearTimeout(timer["/members/login"]);

      ErrorHandle("Login", {
        Method: "POST",
        Url: "/members/login",
        Msg: err,
        StatusCode: err.response.status || "",
      });
    }
  },
  //會員註冊
  Add_MemberInfo: async (Arg) => {
    try {
      let { data } = await MemberAPI.post("/members", Arg);
      return data;
    } catch (err) {
      clearTimeout(timer["/members"]);
      ErrorHandle("Add_MemberInfo", {
        Method: "POST",
        Url: "/members",
        Msg: err,
        StatusCode: err.response.status || "",
      });
    }
  },
  //會員歸戶
  Add_ImportToAccount: async (Arg) => {
    let Config = JSON.parse(JSON.stringify(baseConfig));
    Config.headers.Authorization = "Bearer " + store.state.token;
    try {
      let { data } = await MemberAPI.post(
        "/accounts",
        Arg,
        Config
      );
      return data;
    } catch (err) {
      clearTimeout(timer["/accounts"]);
      ErrorHandle("Add_ImportToAccount", {
        Method: "POST",
        Url: "/accounts",
        Msg: err,
        StatusCode: err.response.status || "",
      });
    }
  },
  //不再提醒
  PATCH_SetNoNeedToRemind: async () => {
    let Config = JSON.parse(JSON.stringify(baseConfig));
    Config.headers.Authorization = "Bearer " + store.state.token;
    try {
      let { data } = await MemberAPI.patch(
        "/accounts/no_remind",
        null,
        Config
      );
      return data;
    } catch (err) {
      console.error(err);
      clearTimeout(timer["/accounts/no_remind"]);
      ErrorHandle("Update_SetNoNeedToRemind", {
        Method: "PATCH",
        Url: "/accounts/no_remind",
        Msg: err,
        StatusCode: err.response.status || "",
      });
    }
  },
  //取得-會員資訊資料*
  Get_MemberInfo: async () => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      // let DESed = window.localStorage.getItem("AC");
      // let Arg = {
      //   EncryptPlatformId: DESed,
      // };
      try {
        let { data } = await MemberAPI.get("/members", Config);
        return data;
      } catch (err) {
        console.error(err);
        clearTimeout(timer["/members"]);
        ErrorHandle("Get_MemberInfo error", {
          Method: "GET",
          Url: "/members",
          Msg: err,
        });
      }
    }
  },
  //取得-Google使用者資訊資料
  Get_Google_Info: async (id_token) => {
    try {
      let { data } = await MemberAPI.get(
        `/members/google_user?id_token=${id_token}`
      );
      return data;
    } catch (err) {
      clearTimeout(timer[`/members/google_user?id_token=${id_token}`]);

      ErrorHandle();
    }
  },
  //-------------------本命盤-------------------

  //新增-好友資料*
  Add_AstrolabeFile: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await MemberAPI.post(`/astrolabes`, Arg, Config);
        return data;
      } catch (err) {
        clearTimeout(timer[`/astrolabes`]);
        ErrorHandle("Add_AstrolabeFile error", {
          Method: "post",
          Url: "/astrolabes",
          Msg: err,
        });
      }
    }
  },
  //取得-好友資料*
  Get_AstrolabeFile: async (AstrolabeFileId) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await MemberAPI.get(
          `/astrolabes/${AstrolabeFileId}`,
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/astrolabes"]);
        ErrorHandle("Get_AstrolabeFile error", {
          Method: "get",
          Url: "/astrolabes",
          Msg: err,
        });
      }
    }
  },
  //修改-好友資料*
  Update_AstrolabeFile: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await MemberAPI.patch(`/astrolabes`, Arg, Config);
        return data;
      } catch (err) {
        clearTimeout(timer["/astrolabes"]);
        ErrorHandle("Update_AstrolabeFile error", {
          Method: "PATCH",
          Url: "/astrolabes",
          Msg: err,
        });
        return false;
      }
    }
  },
  //刪除-好友資料*
  Delete_AstrolabeFile: async (AstrolabeFileId) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await MemberAPI.delete(
          `/astrolabes/${AstrolabeFileId}`,
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/astrolabes/"]);
        ErrorHandle("Delete_AstrolabeFile error", {
          Method: "delete",
          Url: "/astrolabes/",
          Msg: err,
        });
      }
    }
  },
  //-----------------------訂單---------------------
  //取得訂單列表資料
  Get_PurchaseRecordList: async () => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await MemberAPI.get(`/orders`, Config);
    }
  },
  //取得訂單明細資料
  Get_PurchaseRecord: async (OrderNo) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await MemberAPI.get(
        `/orders/${OrderNo}`,
        Config
      );
    }
  },
  //兌換碼查詢
  GET_ExchangeCode: async (OrderNo) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await MemberAPI.get(`/orders/${OrderNo}/gift_code`, Config);
    }
  },
};
