<template>
  <FixedPopup :class="{ onFocus: onFocus }">
    <!-- 第0階段 -->
    <div class="stepBox step0Box" v-if="nowStep == '0'">
      <div class="stepBox__pic">
        <img src="@/assets/插圖/s1.png" alt />
      </div>
      <div class="stepBox__content">
        <h2>想進行帳號歸戶嗎？</h2>
        <div class="stepBox__content__desc">
          曾經登入發現「已解鎖內容」需再次付費，可能是過去「使用不同帳號登入」所導致。您可以透過「帳號歸戶」來整合管理所有帳戶星盤與訂單，或是「維持原樣」各別管理不同帳號。
        </div>
        <label class="checkbox-pri">
          <input type="checkbox" v-model="setNoNeedToRemind" />
          <span class="checkmark"></span>
          不再提醒
        </label>
      </div>
      <div class="stepBox__footer">
        <div class="step0Box__skip" @click="nextTime()">下次再說</div>
        <div class="step0Box__start" @click="nowStep = 1">帳號歸戶</div>
      </div>
      <div class="step0Box__swapAccount">
        想使用其他帳號登入嗎?
        <span @click="swapAccount()">改用其他帳號</span>
      </div>
    </div>
    <!-- 第一階段 -->
    <div class="stepBox step1Box" v-if="nowStep == '1'">
      <div class="stepBox__pic">
        <img src="@/assets/插圖/s1.png" alt />
      </div>
      <div class="stepBox__close" @click="nextTime()">
        <img src="@/assets/icon_menu-close-dark.svg" />
      </div>
      <div class="stepBox__content">
        <h2>帳號歸戶設定</h2>
        <div class="stepBox__content__desc">
          若曾透過 Line、Facebook 或 Google
          註冊過帳號，「帳號歸戶」可以將您各帳號的好友與星盤合併在一起。
        </div>
        <div class="step1Box__form">
          <div class="step1Box__form__input">
            <input
              type="email"
              id="email"
              placeholder="輸入 Email 來歸戶帳號"
              v-model.trim="recipientMail"
              @hover="onFocus = true"
              @blur="
                onFocus = false;
                emailFormatError = false;
              "
            />
          </div>
          <div
            class="step1Box__form__submit"
            :class="{ disable: waiting || !recipientMail }"
            @click="step1__sendEmail()"
          >
            驗證
          </div>
        </div>
        <p v-show="emailFormatError" class="inputError">Email格式有誤!</p>
        <p v-show="emailSendFrequent" class="inputError">
          您的信件驗證已達本日上限，請確認您輸入的信箱是否正確或是檢查驗證信是否誤判為垃圾郵件，謝謝！
        </p>
      </div>
    </div>
    <!-- 第二階段 -->
    <div class="stepBox step2Box" v-if="nowStep == '2'">
      <div class="stepBox__pic">
        <img src="@/assets/插圖/develop.svg" alt />
      </div>
      <div class="stepBox__content">
        <h2>信箱驗證</h2>
        <div class="stepBox__content__desc">
          驗證碼已寄至您的信箱，請輸入驗證碼
        </div>
        <div class="step2Box__form">
          <div class="verification-code-input" :class="{ captchaError }">
            <div class="verification-code-input-group">
              <input
                v-for="(input, index) in inputs"
                type="tel"
                :key="index"
                :ref="`input${index}`"
                v-model="input.value"
                @input="handleInput(index)"
                @keydown="handleKeyDown(index, $event)"
                :class="{ active: input.active }"
                maxlength="1"
                autocomplete="off"
                placeholder="●"
                @focus="onFocus = true"
                @blur="onFocus = false"
              />
            </div>
            <p v-show="captchaError" class="inputError">請輸入正確的驗證碼</p>
          </div>
        </div>
        <p class="step2Box__resend">
          <span class="step2Box__resend__time">
            {{ block_countdown + "s" }}
          </span>
          後將可以重新發驗證碼
          <span
            class="step2Box__resend__btn"
            @click="step1__sendEmail()"
            :class="{ active: block_CanSend }"
            >發送驗證碼</span
          >
        </p>
      </div>
      <div
        class="step2Box__submit"
        :class="{ notReady: inputCaptcha.length !== 6 }"
        @click="importToAccount()"
      >
        送出
      </div>
      <div class="step2Box__back" @click="backStep1()">回上一頁</div>
    </div>
    <!--第三階段 驗證完成 -->
    <div class="step3Box" v-if="nowStep == '3'">
      <img class="step3Box__img" src="@/assets/ok.svg" alt />
      <div class="step3Box__title">信箱已驗證成功，謝謝您！</div>
      <div class="step3Box__close" @click="nowStep = '4'">
        <img src="@/assets/close.svg" alt />
      </div>
    </div>
    <!--第四階段 成功說明 -->
    <div class="stepBox step4Box" v-if="nowStep == '4'">
      <div class="stepBox__pic">
        <img src="@/assets/插圖/s1.png" alt />
      </div>
      <div class="stepBox__content">
        <h2>帳號歸戶成功</h2>
        <div class="stepBox__content__desc">
          已成功將您的
          {{ sccessPlatforms.join("、") }}
          整合在同一個帳號下。未來您可以自由地透過 Facebook、Line、Google
          或信箱來登入帳號。
          <br />
          <br />點擊「前往好友列表」，您可以看到合併後的好友名單喔~
        </div>
      </div>
      <div class="step4Box__btnGroup">
        <div class="step4Box__btnGroup__toFriend" @click="toFriend()">
          前往好友列表
        </div>
        <div class="step4Box__btnGroup__toImportList" @click="toImportList()">
          查看帳號歸戶
        </div>
      </div>
    </div>
    <!--第五階段 說明 -->
    <div class="stepBox step5Box" v-if="nowStep == '5'">
      <div class="stepBox__pic">
        <img class="hidden-mobile-down" src="@/assets/歸戶提示.png" alt />
        <img class="hidden-mobile-up" src="@/assets/歸戶提示_m.png" alt />
      </div>
      <div class="stepBox__content">
        <h2>
          未來仍可從個人帳號中
          <br />設定「帳號歸戶」喔
        </h2>
        <div class="stepBox__content__desc">
          未來若想設定「帳號歸戶」，您仍可以透過點擊個人帳號的選單，來設定「帳號歸戶」。
        </div>
      </div>
      <div class="step5Box__btn" @click="close()">了解</div>
    </div>
  </FixedPopup>
</template>

<script>
import FixedPopup from "./Fixed_popup";
export default {
  components: { FixedPopup },
  data() {
    return {
      nowStep: 0,
      waiting: false,
      recipientMail: "",
      //step2
      inputs: [
        { value: "", active: true },
        { value: "", active: false },
        { value: "", active: false },
        { value: "", active: false },
        { value: "", active: false },
        { value: "", active: false },
      ],
      emailFormatError: false,
      emailSendFrequent: false,
      block_countdown: 0,
      block_CanSend: true,
      block_error: false,
      captchaError: false,
      sccessPlatforms: [],
      setNoNeedToRemind: false,
      onFocus: false,
      timer: null,
    };
  },
  computed: {
    UserData() {
      return this.$store.state.userData; //星盤設置設定檔
    },
    inputCaptcha() {
      return this.inputs.map((item) => item.value).join("");
    },
  },
  created() {
    let isImport = window.location.href.indexOf("isImport") > -1;
    this.nowStep = isImport ? 1 : 0;
  },
  methods: {
    async step1__sendEmail() {
      if (!this.block_CanSend) return;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(this.recipientMail)) {
        this.waiting = true;
        let res = await this.$API.POST_MemberCaptchaMail({
          MemberId: this.UserData.MemberId,
          RecipientMail: this.recipientMail,
        });
        if (!res.Data.Result) return (this.emailSendFrequent = true);
        this.waiting = false;
        this.$store.commit("update_toast", ["成功將驗證碼寄至您的信箱"]);
        this.initStep2();
      } else {
        this.emailFormatError = true;
      }
    },
    initStep2() {
      this.nowStep = 2;
      this.block_countdown = 300;
      this.block_CanSend = false;
      this.timer = setInterval(() => {
        this.block_countdown--;
        if (this.block_countdown < 1) {
          clearInterval(this.timer);
          this.block_CanSend = true;
        }
      }, 1000);
    },
    handleInput(index) {
      if (this.inputs[index].value !== "") {
        this.inputs[index].active = false;
        if (index < this.inputs.length - 1) {
          this.inputs[index + 1].active = true;
          this.$refs[`input${index + 1}`][0].focus();
        }
      }
    },
    handleKeyDown(index, event) {
      this.captchaError = false;
      if (event.keyCode === 8 && this.inputs[index].value === "") {
        this.inputs[index].active = false;
        if (index > 0) {
          this.inputs[index - 1].active = true;
          this.$refs[`input${index - 1}`][0].focus();
        }
      }
    },
    //進行驗證&歸戶
    importToAccount() {
      this.$API
        .Add_ImportToAccount({
          RecipientMail: this.recipientMail,
          Captcha: this.inputCaptcha,
        })
        .then((res) => {
          if (!res.Data.Result) {
            this.captchaError = true;
            this.inputs = this.inputs.map((item) => {
              item.value = "";
              item.active = false;
              return item;
            });
          } else {
            this.nowStep = 3;
            this.sccessPlatforms = res.Data.Platforms;
          }
        });
    },
    //至好友列表
    toFriend() {
      this.$store.commit("update_showRegistation", false);
      this.$router.push("/friendList");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
    toImportList() {
      this.$store.commit("update_showRegistation", false);
      this.$router.push("/member/importList");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
    //下次再說
    nextTime() {
      console.log("this.setNoNeedToRemind",this.setNoNeedToRemind)
      if (this.setNoNeedToRemind) {
        this.nowStep = 5;
        this.$API.PATCH_SetNoNeedToRemind();
      } else {
        this.close();
      }
    },
    close() {
      this.$store.commit("update_showRegistation", false);
    },
    //切換帳號
    swapAccount() {
      window.localStorage.removeItem("login_status");
      window.localStorage.removeItem("AC");
      window.localStorage.removeItem("imgUrl");
      window.localStorage.removeItem("haveNoData");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("LT");
      window.location = this.$store.state.app_return_url + "?login=all";
    },
    backStep1() {
      this.nowStep = 1;
      this.recipientMail = "";
      this.block_CanSend = true;
      clearInterval(this.timer);
    },
  },
};
</script>
<style lang="scss">
@import "@/scss/_mixin.scss";

//共用
.stepBox {
  @include rect(520px, auto, $theme-color-3, 50px);
  padding: 28px 36px;
  box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
    4px 4px 25px rgba(0, 0, 0, 0.3);
  font-family: $tw_font;
  @include mobile() {
    padding: 48px 36px;
    @include rect(326px, auto, $theme-color-3, 100px);
  }
  position: relative;
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 48px;
    height: 48px;
    cursor: pointer;
    @include mobile {
      top: 32px;
      right: 32px;
      width: 40px;
      height: 40px;
    }
    img {
      width: 100%;
    }
  }
  &__pic {
    width: 220px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    @include mobile {
      height: 200px;
      width: unset;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__content {
    margin-top: 28px;
    h2 {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #17445c;
      text-align: center;
    }
    &__desc {
      margin-top: 20px;
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.04em;
      color: #868686;
      text-align: left;
      @include mobile {
        margin-top: 8px;
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
  &__footer {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
}
// //第零階段
.step0Box {
  height: 661px;
  @include mobile {
    height: 696px;
  }
  &__skip {
    @include btn(172px, 44px, transparent, #9b9b9b, 16px);
    border: 1px solid #9b9b9b;
    margin-right: 16px;
    @include mobile {
      margin-right: 8px;
      @include btn(123px, 33px, transparent, #9b9b9b, 14px);
    }
  }
  &__start {
    @include btn-default(172px, 44px, 16px);
    @include mobile {
      @include btn-default(123px, 33px, 14px);
    }
  }
  &__swapAccount {
    margin-top: 32px;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #9b9b9b;
    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
// //第一階段
.step1Box {
  height: 541px;
  @include mobile {
    height: 552px;
  }
  &__form {
    margin-top: 28px;
    display: flex;
    justify-content: center;
    &__input {
      flex-grow: 1;
      height: 40px;
      padding: 0 16px;
      background: #f0f2f4;
      box-shadow: 2px 2px 4px #ffffff, inset 2px 2px 4px rgba(0, 0, 0, 0.15) !important;
      border-radius: 50px;
      color: #17445c;
      @include mobile {
        width: 198px;
        border-radius: 20px;
      }
    }
    input {
      width: 100%;
      height: 100%;
      font-size: 14px;
      line-height: 14px;
      &::placeholder {
        font-size: 14px;
        line-height: 40px;
        color: #d9d9d9;
      }
      &:focus {
        outline: none;
      }
    }

    &__submit {
      flex-shrink: 0;
      @include btn-default(112px, 44px, 16px);
      border-radius: 50px;
      margin-left: 16px;
      @include mobile {
        @include btn-default(64px, 40px, 14px);
        border-radius: 20px;
        margin-left: 8px;
      }
      &.disable {
        background-color: #9d9d9d;
        pointer-events: none;
      }
    }
  }
  .inputError {
    width: 100%;
    color: #e2562c;
    font-size: 14px;
    margin-top: 2px;
  }
}

// //第二階段
.step2Box {
  min-height: 624px;
  @include mobile {
    min-height: 628px;
  }
  .stepBox__content__desc {
    text-align: center;
  }
  &__form {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .verification-code-input {
      display: flex;
      flex-direction: column;
      &-group {
        display: flex;
        input {
          width: 48px;
          border: none;
          text-align: center;
          font-size: 28px;
          line-height: 28px;
          font-weight: 700;
          margin: 0 4px;
          background: none;
          outline: none;
          padding-bottom: 8px;
          color: #17445c;
          border-bottom: 2px solid rgba(#17445c, 0.2);
          @include mobile {
            width: 36px;
          }
          &::placeholder {
            font-size: 16px;
            color: rgba(#17445c, 0.2);
          }
        }
      }
      &.captchaError input {
        border-bottom: 2px solid #e2562c;
      }
      .inputError {
        width: 100%;
        color: #e2562c;
        font-size: 14px;
        margin-left: 4px;
      }
    }
  }
  &__resend {
    margin-top: 16px;
    text-align: center;
    color: #868686;
    font-size: 12px;
    line-height: 16px;
    &__time {
      color: #ab956c;
    }
    &__btn {
      color: #d9d9d9;
      &.active {
        cursor: pointer;
        color: #ab956c;
      }
    }
  }
  &__submit {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    @include btn-default(176px, 44px, 16px);
    @include mobile {
      margin-top: 72px;
      @include btn-default(235px, 40px, 14px);
    }
  }
  &__back {
    margin-top: 20px;
    color: #798da5;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
  }
}

// //第三階段
.step3Box {
  @include rect(320px, 224px, $theme-color-3, 40px);
  box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
    4px 4px 25px rgba(0, 0, 0, 0.3);
  color: #ab956c;
  font-weight: 700;
  @include center;
  flex-direction: column;
  position: relative;
  &__img {
    width: 64px;
    height: 64px;
    margin-bottom: 16px;
  }
  &__close {
    position: absolute;
    top: 15px;
    right: 24px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    & > img {
      width: 100%;
    }
  }
}
// //第四階段
.step4Box {
  min-height: 617px;
  @include mobile {
    min-height: 668px;
  }
  &__btnGroup {
    margin: 40px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include mobile {
      margin-top: 72px;
    }
    &__toFriend {
      @include btn-default(235px, 40px, 14px);
    }
    &__toImportList {
      margin-top: 20px;
      color: #798da5;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
    }
  }
}

.step5Box {
  min-height: 645px;
  @include mobile {
    min-height: 729px;
  }
  h2 {
    line-height: 1.5;
  }
  .stepBox__pic {
    width: 320px;
    height: 315px;
    border: 2px solid #ffffff;
    border-radius: 50px;
    @include mobile {
      width: 216px;
      height: 389px;
    }
  }
  .stepBox__content__desc {
    text-align: center;
  }
  &__btn {
    margin: 48px auto 0;
    @include btn-default(176px, 44px, 16px);
  }
}
</style>
