<template>
  <v-app id="all" :class="{ bookStyle: bookStyle }" v-if="app_ready">
    <NavBar :navlogined="afterLogin" />
    <v-main>
      <router-view />
    </v-main>
    <Footer />
    <Toast />
    <loadingAnimation v-if="loading || loading_force"></loadingAnimation>
    <Warnging />
    <Message />
    <AlreadyWriteVue />
    <Notice />
    <ImageNotice />
    <Registration v-if="showRegistation" />
    <NewMoonCTAVue
      v-if="todayIsNewMoon && showNewMoonNotice"
      v-show="!excludeNewMoonCTA.includes(currentRouteName)"
    ></NewMoonCTAVue>
    <SelectAstrolabePopup />
  </v-app>
</template>

<script>
import Warnging from "@/components/Warning.vue";
import Message from "@/components/Message";
import Toast from "@/components/Toast.vue";
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import loadingAnimation from "./components/LoadingAnimation";
import jwt from "jsonwebtoken";
import Registration from "./components/Registration.vue";
import AlreadyWriteVue from "./components/月亮日記/AlreadyWrite.vue";
import NewMoonCTAVue from "./components/月亮日記/NewMoonCTA.vue";
import Notice from "./components/Notice.vue";
import ImageNotice from "./components/ImageNotice.vue";
import SelectAstrolabePopup from "./components/SelectAstrolabePopup.vue";
export default {
  name: "App",
  //vue-meta設置
  metaInfo() {
    return {
      title: "唐綺陽官方專屬星盤",
      meta: [
        {
          name: "description",
          content: ` 唐綺陽專屬星盤，導讀你的人生課題！星盤，就像人生地圖，描述著我們會用什麼方式前進，以及想走往哪個目的地。期待每個人都可以受惠這門超越科學的學問，更了解人生、更認識自己。唐綺陽專屬星盤，導讀你的人生課題！星盤，就像人生地圖，描述著我們會用什麼方式前進，以及想走往哪個目的地。期待每個人都可以受惠這門超越科學的學問，更了解人生、更認識自己。`,
        },
        {
          name: "Keywords",
          content: `唐綺陽、占星、星盤、星座命盤、星座運勢、十二星座、12星座、星座解析、線上星盤`,
        },
        {
          name: "og:image",
          property: "og:image",
          content:
            this.$route.fullPath.indexOf("book") > -1
              ? `https://i.imgur.com/5n5gMaQ.jpg`
              : `https://i.imgur.com/eB82Sl9.jpg`,
        },
        {
          name: "twitter:image",
          property: "twitter:image",
          content:
            this.$route.fullPath.indexOf("book") > -1
              ? `https://i.imgur.com/5n5gMaQ.jpg`
              : `https://i.imgur.com/eB82Sl9.jpg`,
        },
      ],
    };
  },
  data() {
    return {
      app_ready: false,
      excludeNewMoonCTA: [
        "NewMoon",
        "MoondayCreate",
        "MoondayEdit",
        "MoondayNote",
      ],
    };
  },
  components: {
    loadingAnimation,
    NavBar,
    Footer,
    Toast,
    Warnging,
    Message,
    Registration,
    AlreadyWriteVue,
    NewMoonCTAVue,
    Notice,
    ImageNotice,
    SelectAstrolabePopup,
  },

  created() {
    this.init();
    // this.app_ready = true;
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    loading() {
      return this.$store.state.loading;
    },
    loading_force() {
      return this.$store.state.loading_force;
    },
    afterLogin() {
      return this.$store.state.afterLogin;
    },
    showRegistation() {
      return this.$store.state.showRegistation;
    },
    todayIsNewMoon() {
      return this.$store.getters.todayIsNewMoon;
    },
    showNewMoonNotice() {
      //預設true，要關掉才會變false
      return this.$store.getters.showNewMoonNotice;
    },
    bookStyle() {
      const bookRegex = /^\/book202[4-7]/;
      return bookRegex.test(this.$route.path);
    },
  },
  methods: {
    init() {
      // TODO: 改為判斷如果是運勢書 URL要是運勢書首頁
      this.$store.commit("set_app_return_url", document.location.origin);

      //測試地理位置
      console.log(this.$API.Encrypt("U7a91feba19cd0f6cef87cd70e1cc96a3"));
      //FB登入初始化
      this.fb_init();
      //判斷是不是Line進來
      this.enter_method_deal();
      //判斷
      if (typeof Storage == "undefined") {
        alert("您的瀏覽器不支援 Web Storage，請更換瀏覽器否則無法登入喔！");
      }
      //存到undefined
      if (
        window.localStorage.getItem("AC") == "375B98AFE9C3475A6B7605EB109A6FCC"
      ) {
        this.logout();
      }
      // 測試用帳號，同一般帳號
      if (process.env.NODE_ENV == "local") {
        localStorage.setItem(
          "AC",
          "4B73C8711D5A03B3EC3E19ECCDE3638E980ECF0007D48892233AD5BF3AC971CF9896630EB074A96C564C354DA0BC7BA9"
        );

        localStorage.setItem("loginMethods", "Line");
        localStorage.setItem(
          "imageUrl",
          "https://i.ytimg.com/vi/_6TtTRrno3E/maxresdefault.jpg"
        );
        localStorage.setItem("haveNoData", false);
      }

      if (
        //有完成第三方登入(FB、GOOGLE)取得UID，進行我方登入
        window.localStorage.getItem("AC") &&
        window.localStorage.getItem("loginMethods")
      ) {
        this.$store.commit("updateLoginStatus", true);
        this.$store.commit(
          "updateHeadImage",
          window.localStorage.getItem("imageUrl")
        );
        this.loginHandler();
      } else {
        //進行LINE登入判斷
        this.$store.commit("updateLoginStatus", false);
        //清除上一次的token
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("AC");
        if (window.location.href.indexOf("afterLineBack") > -1) {
          this.Line_loginHandler();
        } else {
          this.app_ready = true;
          this.initBookStatus();
          window.localStorage.removeItem("toWhere");
        }
      }
      console.log("v20241026_6", process.env.NODE_ENV);
    },
    Line_loginHandler() {
      //Line處理開始
      let lineData = {
        grant_type: "authorization_code",
        redirect_uri: this.$store.state.app_return_url, //正式
        client_id: process.env.VUE_APP_client_id, // 正式
        client_secret: process.env.VUE_APP_client_secret, //正式
        code: window.location.search.split("code=")[1].split("&")[0],
      };
      let passData = this.Qs.stringify(lineData);
      this.$API
        .Line_LoginAuth(passData)
        .then((data) => {
          window.localStorage.setItem("LT", data.access_token);
          let email = jwt.decode(data.id_token).email;
          window.localStorage.setItem("email", email);
          this.$API.Get_Line_data().then((response) => {
            let AC = this.$API.Encrypt(response.userId);
            window.localStorage.setItem("before", response.userId);
            if (!response.userId) {
              window.location = this.$route.meta.logoutDirectIndexPage || "/";
            }
            window.localStorage.setItem("imageUrl", response.pictureUrl);
            this.$store.commit("updateHeadImage", response.pictureUrl);
            localStorage.setItem("loginMethods", "Line");
            window.localStorage.setItem("AC2", AC);
            window.localStorage.setItem("AC", AC);
            //進行登入
            this.loginHandler();
          });
        })
        .catch(() => {});
    },
    loginHandler() {
      try {
        //我方登入

        const account = window.localStorage.getItem("AC");
        this.$API.Login(account).then(async (data) => {
          window.localStorage.setItem("login_status", true);
          this.$store.commit(
            "updateHeadImage",
            window.localStorage.getItem("imageUrl")
          );
          this.$store.commit("updateLoginStatus", true);
          if (data.Data == null) {
            //沒有得到token，因此進行註冊
            window.localStorage.setItem("haveNoData", true);
            this.app_ready = true;
            this.$router.push({ path: "member-create" });
          } else {
            //跳轉到該去的地方(2023/12/17)
            let directionPath = window.localStorage.getItem("directionPath");
            if (directionPath) {
              this.$router.replace(directionPath);
              window.localStorage.removeItem("directionPath");
            }

            //得到token，將token保存，並呼叫取得會員資料
            window.localStorage.setItem("haveNoData", false);
            window.localStorage.setItem("token", data.Data.Token);
            this.$store.commit("set_token", data.Data.Token);
            window.localStorage.setItem(
              "loginIndex",
              data.Data.MemberDetail.MemberId
            );
            window.localStorage.setItem("loginIndex", data.Data.MemberId);

            this.$store.commit("set_userData", data.Data.MemberDetail);

            let self = this.$store.getters.SELF_OBJ;
            const res = await this.$API.Get_AstrolabeFile(self.AstrolabeFileId);
            this.$store.commit("set_nowUserData", res.Data);

            //占星小舖初始化
            await this.initStore();

            //檢查今天是不是新月
            this.checkTodayIsNewMoon();

            //初始化運勢書解鎖狀況
            await this.initBookStatus();

            //真正意義上的初始化完成，開始渲染組件
            this.app_ready = true; //真的有獲取userData，開始渲染子組件

            if (!this.$store.state.userData) {
              //解析失敗 須重置
              this.logout();
            }
            this.popEventHandler(data);
          }
        });
      } catch (err) {
        this.app_ready = true;
        console.error(err);
      }
    },
    //FB登入初始化
    fb_init() {
      window.fbAsyncInit = function () {
        if (window.FB) {
          window.FB.init({
            appId: process.env.VUE_APP_facebookID,
            xfbml: true,
            cookie: true,
            version: "v18.0",
          });
          window.FB.AppEvents.logPageView();
          window.FB.getLoginStatus();
        }
      };
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    },
    //進入點處理(判斷是否為LINE瀏覽器開啟，但20230506已全面改為openExternalBrowser)
    enter_method_deal() {
      let url = window.location.search;
      if (
        url.indexOf("openExternalBrowser") > -1 &&
        window.localStorage.getItem("loginMethods") != "Line"
      ) {
        //藉由圖文選單的openExternalBrowser進入，但loginMethods不是LINE
        this.logout(false);

        //強制觸發LINE登入
        this.$store.state.LoginOutSide = false;
        let url = "https://access.line.me/oauth2/v2.1/authorize?";
        url += "response_type=code";
        url += "&client_id=" + process.env.VUE_APP_client_id; //正式
        url += "&redirect_uri=" + this.$store.state.app_return_url; //正式
        url += "&state=afterLineBack";
        url += "&scope=openid%20profile";
        // %20email
        return (window.location.href = url);
      }
      let queryArry = url.split("&");
      if (queryArry[0] == "?source=line") {
        window.localStorage.setItem("source", "Line");
        window.localStorage.setItem("FromNode", "Line");
      }
      // Line瀏覽器判定
      if (
        this.$route.query.source === "Line" ||
        window.localStorage.getItem("source") === "Line"
      ) {
        this.$store.state.FromLineWebApp = true;
      }
    },
    logout(refresh = true) {
      window.localStorage.removeItem("login_status");
      window.localStorage.removeItem("AC");
      window.localStorage.removeItem("imgUrl");
      window.localStorage.removeItem("haveNoData");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("LT");
      if (refresh) {
        window.location = this.$route.meta.logoutDirectIndexPage || "/";
      }
    },
    async initStore() {
      try {
        let res = await this.$API.GET_Products();
        this.$store.commit("set_service_list", res.data.Data);
      } catch (err) {
        console.error(err);
      }
    },
    async checkTodayIsNewMoon() {
      try {
        let res = await this.$API.GET_MoonDayCalendar();
        this.$store.commit("setTodayIsNewMoon", res.data.Data);
      } catch (err) {
        console.error(err);
      }
    },
    async initBookStatus() {
      let _book2024_productionList = new Map();
      let _book2024_buyAllObj = null;
      try {
        let res = await this.$API.GET_BOOKLIST();
        let bookObj = res.data.Data.find((item) => item.BookID == 1) || {};
        bookObj.ProductList.forEach((item) => {
          _book2024_productionList.set(item.Code, item);
          if (item.Code == "All") {
            _book2024_buyAllObj = item;
          }
        });
      } catch (err) {
        console.error("Failed to get book list", err);
        throw err;
      }

      //判斷解鎖列表
      let userUnlockList = [];
      if (this.$store.state.afterLogin) {
        if (this.$store.state.userData.BookMap) {
          userUnlockList =
            this.$store.state.userData.BookMap["2024"].ConstellationList;
        }
      }

      let _productList = this.$Astro.map((astro, index) => {
        let productObj = _book2024_productionList.get(astro.en) || {};
        let isUnlock = this.$store.state.afterLogin
          ? userUnlockList.includes(astro.en)
          : false;
        return {
          ...productObj,
          ...astro,
          isUnlock,
        };
      });
      if (_book2024_buyAllObj) {
        _book2024_buyAllObj.isUnlock = userUnlockList.length >= 12;
        _productList.push(_book2024_buyAllObj);
      }
      this.$store.commit("set_book2024_list", _productList);
    },
    giverGiftHandler() {
      this.$imageNotice({
        imageIdx: 1,
        title: "收到禮物囉",
        okText: "查看我的禮物盒",
        desc: `恭喜！您收到朋友贈送給你的禮物喔！`,
        okEvent: () => {
          this.$router.push({ name: "member-giftBox" });
        },
      });
    },
    enableGiftSuccessHandler(gift, AstrolabeFileId) {
      this.$imageNotice({
        imageIdx: 1,
        title: `成功啟用${gift.ProductName}`,
        desc: `已成功啟用${gift.GiverName} (ID:${gift.GiverMemberID}) 贈送給你的「${gift.ProductName}」禮物喔！`,
        okText: `前往${gift.ProductName}`,
        okEvent: () => {
          switch (gift.ProductName) {
            case "宮位解讀":
              this.$API.Get_AstrolabeFile(AstrolabeFileId).then((data) => {
                this.$store.commit("set_nowUserData", data.Data);
                return this.$router.push("/astrolabe");
              });
              break;
            case "推運功能":
              return this.$router.push("/timelineAstrolabe");
            case "月亮日記":
              return this.$router.push("/moonday-list");
            default:
              return this.$router.push("/book2024/my");
          }
        },
        cancelText: "返回至當前頁面",
        cancelEvent: () => {
          window.location.reload();
        },
      });
    },
    //所有彈跳窗事件
    async popEventHandler(data) {
      //歸戶判斷(2023/04/09新增) -> 暫時for已註冊用戶
      let isImport = window.location.href.indexOf("isImport") > -1;
      if (
        isImport ||
        (!data.Data.MemberDetail.HasAccount &&
          !data.Data.MemberDetail.NoNeedToRemind)
      ) {
        setTimeout(() => {
          this.$store.commit("update_showRegistation", true);
        }, 1000);
      } else if (window.location.href.indexOf("enableGiftSuccess=true") > -1) {
        // 判斷是否啟用禮物成功
        let targetGift = JSON.parse(localStorage.getItem("targetGift"));
        let AstrolabeFileId = localStorage.getItem("targetGift_id");
        setTimeout(() => {
          this.enableGiftSuccessHandler(targetGift, AstrolabeFileId);
        }, 1000);
      } else if (data.Data.MemberDetail.HasUnenabledGifts) {
        //判斷是不是有收到禮物
        setTimeout(() => {
          this.giverGiftHandler();
        }, 500);
      }
    },
  },
};
</script>
<style lang="scss">
@import "./scss/all.scss";

#all {
  background-color: $theme-color-3;
  overflow: hidden;
  &.bookStyle {
    background: linear-gradient(0deg, #567da1 0%, #2e517a 51.5%, #567da1 100%),
      #f7f0f8;
  }
}

.bookStyle .third-title {
  display: inline;
  background-color: #d3409d;
  padding: 0 4px;
  border-radius: 4px;
}

.custom-container-xl {
  max-width: 1472px;
  margin: 0 auto;
  padding: 0 16px;
}

.custom-container {
  max-width: 1056px;
  margin: 0 auto;
  padding: 0 16px;
}

.custom-row {
  display: flex;
}

.custom-bullet {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  box-shadow: -1px -1px 4px 0 #ffffff, -1px -1px 4px 0 #ffffff,
    2px 2px 6px 0 rgba(0, 0, 0, 0.25);
  background-color: #f0f2f4;
  margin: 0 6px;
  // outline: none;
  // cursor: pointer;
  @include mobile {
    width: 6px;
    height: 6px;
  }
}

.custom-bullet-active {
  width: 56px;
  box-shadow: 2px 2px 4px 0 #ffffff, inset 2px 2px 4px 0 rgba(0, 0, 0, 0.15);
  @include mobile {
    width: 32px;
  }
}

.v-dialog {
  margin: 0 !important;
}

.v-overlay .v-overlay__scrim {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}

.picker {
  font-family: "Noto Sans TC", "Microsoft JhengHei", "Helvetica", sans-serif;
  background-color: $theme-color-3 !important;

  .v-picker__body {
    background-color: $theme-color-3 !important;
  }

  &.date-picker {
    .v-date-picker-title {
      .v-date-picker-title__year {
        color: rgba($color: #292929, $alpha: 0.5);
        font-size: 16px;
        letter-spacing: 1.28px;
      }

      .v-date-picker-title__date {
        color: rgba($color: #292929, $alpha: 0.7);
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 1.6px;
      }
    }

    .v-date-picker-header .v-date-picker-header__value button {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.25;
      letter-spacing: 1.28px;
      color: $secondary-color-1 !important;
    }
  }

  &.time-picker {
    .v-time-picker-title__time .v-picker__title__btn,
    .v-time-picker-title__time span {
      color: rgba($color: #292929, $alpha: 0.7);
      font-size: 60px;
      font-weight: bold;
      letter-spacing: 4.8px;
    }
    .v-picker__title__btn:not(.v-picker__title__btn--active) {
      opacity: 0.3;
    }

    .v-time-picker-title__ampm {
      font-size: 16px;
      color: rgba($color: #292929, $alpha: 0.7);
      font-weight: bold;
      line-height: 1.25;
      letter-spacing: 1.28px;
    }

    .v-time-picker-clock {
      box-shadow: -2px -2px 4px 0 #ffffff, -2px -2px 4px 0 #ffffff,
        4px 4px 10px 0 rgba(0, 0, 0, 0.2);
      background-color: $theme-color-3;
    }
    .v-time-picker-title__ampm .v-picker__title__btn {
      font-size: 0px;
      &:nth-of-type(1):after {
        content: "上午";
        font-size: 20px;
      }
      &:nth-of-type(2):after {
        content: "下午";
        font-size: 20px;
      }
    }
  }
}

.select {
  &.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: none !important;
    padding: 0;
  }

  &.v-text-field.v-text-field--solo .v-input__control {
    min-height: 0;
  }

  .v-select__selections {
    color: $secondary-color-1 !important;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 1.6px;
    @include mobile {
      font-size: 16px;
      line-height: 1;
      letter-spacing: 0.56px;
    }
  }
}

@media only screen and (min-width: 1264px) {
  .hidden-laptop-up {
    display: none !important;
  }
}

@media only screen and (max-width: 1263px) {
  .hidden-laptop-down {
    display: none !important;
  }
}

@media only screen and (min-width: 960px) {
  .hidden-pad-up {
    display: none !important;
  }
}

@media only screen and (max-width: 959px) {
  .hidden-pad-down {
    display: none !important;
  }
}

@media only screen and (min-width: 577px) {
  .hidden-mobile-up {
    display: none !important;
  }
}

@media only screen and (max-width: 576px) {
  .hidden-mobile-down {
    display: none !important;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
@keyframes fade {
  from {
    opacity: 1;
    display: inline;
    bottom: 20px;
  }
  15% {
    bottom: 10px;
  }
  30% {
    bottom: 20px;
  }
  45% {
    bottom: 10px;
  }
  60% {
    bottom: 20px;
  }
  75% {
    bottom: 10px;
  }
  90% {
    bottom: 20px;
    opacity: 0.8;
  }
  to {
    display: none;
    opacity: 0;
  }
}
</style>
