import Vue from "vue";
import Vuex from "vuex";
import Astrolabe from "./store/astrolabe"; //個人
import TimeLine from "./store/timeline"; //推運
import Astrometry from "./store/astrometry"; //天象
import Moonday from "./store/moonday"; //月亮日
import DBL from "./store/DBL.js"; //合盤

Vue.use(Vuex);
let temp_time = null;
export default new Vuex.Store({
  modules: {
    Astrolabe: Astrolabe,
    TimeLine: TimeLine,
    Astrometry: Astrometry,
    Moonday: Moonday,
    DBL
  },
  state: {
    afterLogin: false, //登入狀態
    loginDialogStatus: false,
    headImage: require("@/assets/cat.png"), //大頭照
    app_return_url: "",
    token: "",
    // AC:"",
    // loginMethods:"",
    needUpdate: false,
    loading: false,
    loading_force: false,
    toastObj: {
      showState: false,
      text: "",
    },
    userData: null,
    myData: null,
    nowUserData: null,
    nowUserData2: null,
    userBeEdit: null,
    LoginOutSide: false,
    FromLineWebApp: false,
    developing: false,
    myDataBeEdit: null,

    //訂單
    orderCreater: {
      Type: 0, //訂單類型 0：自用 1：禮物 2：禮物兌換碼
      ProductID: null,
      Quantity: 1,
      Amount: 0,
      Name: "",
      Phone: "",
      Email: "",
      IsSaveBasic: false,
      CarrierType: "1", //為"2"時填寫CarrierNum
      UniformNum: "",
      ReceiverMemberId: null,
      Parameters: null,

      //送單前要刪除的資料
      Code: "",
      AstrolabeFileIds: [], //{'AstrolabeFileId':504}
      SynastryDtos: [],// { SynastryDto: { Relationships: 'Couple', AstrolabeFileId1: 'XX', AstrolabeFileId2: 'OO' } }
    },
    productList: null, //小舖服務
    showRegistation: false, //進行歸戶
    //運勢書
    book2024_list: [],
    //運勢書2024預購模式
    book2024_preorder: false,
  },
  mutations: {
    //打開登入視窗
    setLoginDialogStatus(state, data) {
      state.loginDialogStatus = data;
    },
    set_token(state, token) {
      state.token = token;
    },
    set_userData(state, userData) {
      state.userData = userData;
    },
    set_app_return_url(state, return_url) {
      state.app_return_url = return_url;
    },
    update_toast(state, [text, time = 3000]) {
      if (temp_time) {
        clearTimeout(temp_time);
      }
      state.toastObj.show = false;
      setTimeout(() => {
        state.toastObj = {
          show: true,
          text,
        };
      }, 100);
      temp_time = setTimeout(() => {
        state.toastObj.show = false;
      }, time);
    },
    set_nowUserData(state, nowUserData) {
      state.nowUserData = nowUserData;
    },
    set_nowUserData2(state, nowUserData2) {
      state.nowUserData2 = nowUserData2;
    },
    updateLoginStatus(state, newValue) {
      state.afterLogin = newValue;
    },
    updateHeadImage(state, newValue) {
      state.headImage = newValue;
    },
    setLoading(state, data) {
      state.loading = data;
    },
    setLoading_force(state, data) {
      state.loading_force = data;
    },
    updateSourceLine(state) {
      state.sourceLine = true;
    },
    updateMyData(state, newValue) {
      let mydata = state.myData || {};
      const {
        AstralInfo,
        AstrolabeFileId,
        BirthCity,
        BirthCountry,
        BirthDay,
        BirthState,
        BirthTime,
        City,
        Constellation,
        Gender,
        IsNew,
        IsRepeat,
        Name,
        ProfilePhotoSrc,
      } = newValue;

      mydata.AstralInfo = AstralInfo || mydata.AstralInfo;
      mydata.AstrolabeFileId = AstrolabeFileId || mydata.AstrolabeFileId;
      mydata.BirthCity = BirthCity || mydata.BirthCity;
      mydata.BirthCountry = BirthCountry || mydata.BirthCountry;
      mydata.BirthDay = BirthDay || mydata.BirthDay;
      mydata.BirthState = BirthState || mydata.BirthState;
      mydata.BirthTime = BirthTime || mydata.BirthTime;
      mydata.City = City || mydata.City;
      mydata.Constellation = Constellation || mydata.Constellation;
      mydata.Gender = Gender || mydata.Gender;
      mydata.IsNew = IsNew || mydata.IsNew;
      mydata.IsRepeat = IsRepeat || mydata.IsRepeat;
      mydata.Name = Name || mydata.Name;
      mydata.ProfilePhotoSrc = ProfilePhotoSrc || mydata.ProfilePhotoSrc;
      mydata.Relationship = "自己";
      state.myData = mydata;
    },
    //變更orderCreater
    updateOrderCreater(state, newObject) {
      state.orderCreater = newObject;
    },
    //取得小舖服務資料
    set_service_list(state, data) {
      state.productList = data;
    },
    //打開歸戶
    update_showRegistation(state, data) {
      state.showRegistation = data;
    },
    //設定運勢書
    set_book2024_list(state, data) {
      state.book2024_list = data;
    },
  },
  actions: {
    setLoading: ({ commit }, newValue) => {
      commit("setLoading", newValue);
    },
  },
  getters: {
    SELF_OBJ(state) {
      let self = state.userData.AstrolabeFriendLists.find(
        (item) => item.Relationship == "R0" && item.IsBelongToMember
      );
      return self || state.userData.AstrolabeFriendLists[0];
    },
    get_book2024_list(state) {
      return state.book2024_list;
    },
    ListDBL(state) {
      return state.userData.SynastryLists;
    },
    productListMap(state) {
      if (!state.productList) return {};
      return state.productList.reduce((acc, item) => {
        acc[item.Code] = item.ProductID;
        return acc;
      }, {});
    }
  },
});
