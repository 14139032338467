import Base from "@/api/_base";
import store from "@/store";

let { CalculateAPI, Encrypt, ErrorHandle, TimeoutHandler } = Base;
let baseConfig = {
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30000,
};
let urlencoded_config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  timeout: 30000,
};
let timer = {};
CalculateAPI.interceptors.request.use(
  function (config) {
    if (timer[config.url]) {
      clearTimeout(timer[config.url]);
    }
    timer[config.url] = setTimeout(() => {
      TimeoutHandler(config.url, config.method);
    }, 30000);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
CalculateAPI.interceptors.response.use(
  function (response) {
    clearTimeout(timer[response.config.url]);
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export default {

  //----------------本命------------------
  //取得本命盤星體資料
  Post_AstralInformation: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await CalculateAPI.post(
          `/natalcharts/astral`,
          { AesEncryptRequestParameters: Encrypt(JSON.stringify(Arg)) },
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/natalcharts/astral"]);
        ErrorHandle("Post_AstralInformation error", {
          Method: "post",
          Url: "/natalcharts/astral",
          Msg: err,
        });
      }
    }
  },
  //取得本命盤解讀資料
  POST_AstrolabeInterpretations: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await CalculateAPI.post(
          `/natalcharts/interpretation`,
          { AesEncryptRequestParameters: Encrypt(JSON.stringify(Arg)) },
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/natalcharts/interpretation"]);
        ErrorHandle("POST_AstrolabeInterpretations error", {
          Method: "post",
          Url: "/natalcharts/interpretation",
          Msg: err,
        });
      }
    }
  },
  //取得本命盤詳細參數資料
  POST_AstrolabeParams: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      Config.closeLoadAnimation = true; //關閉動畫
      try {
        let { data } = await CalculateAPI.post(
          `/natalcharts/parameter`,
          { AesEncryptRequestParameters: Encrypt(JSON.stringify(Arg)) },
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/natalcharts/parameter"]);
        ErrorHandle("POST_AstrolabeParams error", {
          Method: "post",
          Url: "/natalcharts/parameter",
          Msg: err,
        });
      }
    }
  },
  //----------------推運盤------------------
  //取得推運盤星體資料(輸入任意時間地點取得弧度)
  POST_TcAstralInfo: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.closeLoadAnimation = true; //關閉動畫
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await CalculateAPI.post(
          `/transitcharts/astral`,
          { AesEncryptRequestParameters: Encrypt(JSON.stringify(Arg)) },
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/transitcharts/astral"]);
        ErrorHandle("POST_TcAstralInfo error", {
          Method: "post",
          Url: "/transitcharts/astral",
          Msg: err,
        });
      }
    }
  },
  //取得推運盤解讀資料
  POST_LcInterpretations: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.closeLoadAnimation = true; //關閉動畫
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await CalculateAPI.post(
          `/transitcharts/interpretation`,
          { AesEncryptRequestParameters: Encrypt(JSON.stringify(Arg)) },
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/transitcharts/interpretation"]);

        ErrorHandle("POST_LcInterpretations error", {
          Method: "post",
          Url: "/transitcharts/interpretation",
          Msg: err,
        });
      }
    }
  },
  //取得推運盤詳細參數資料
  POST_TcAstrolabeParams: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.closeLoadAnimation = true; //關閉動畫
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await CalculateAPI.post(
          `/transitcharts/parameter`,
          { AesEncryptRequestParameters: Encrypt(JSON.stringify(Arg)) },
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/transitcharts/parameter"]);
        ErrorHandle("POST_LcInterpretations error", {
          Method: "post",
          Url: "/transitcharts/parameter",
          Msg: err,
        });
      }
    }
  },

  //----------------合盤------------------
  //取得合盤解讀資料
  POST_SynastrysAstralInfo: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await CalculateAPI.post(
          `/synastrys/interpretation`,
          { AesEncryptRequestParameters: Encrypt(JSON.stringify(Arg)) },
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/synastrys/interpretation"]);

        // ErrorHandle("POST_LcInterpretations error", {
        //   Method: "post",
        //   Url: "/synastrys/interpretation",
        //   Msg: err,
        // });
      }
    }
  },
};
