export default {
  data() {
    return {};
  },
  computed: {
    _buyAllObj() {
      return this.$store.getters.get_book2024_list.find(
        (item) => item.Code === "All"
      );
    },
  },
  methods: {
    mixin_buySingle(nowProductObj, useType, giftMode, receiverMemberId, butQuantity) {
      if (!this.$store.state.afterLogin) {
        window.localStorage.setItem("directionPath", this.$route.fullPath);
        return this.$store.commit("setLoginDialogStatus", true);
      }
      let orderObj = this.$store.state.orderCreater;
      orderObj.Quantity = butQuantity || 1;
      orderObj.ProductID = nowProductObj.ProductID;
      //Astrolabe為自己
      let selfId = this.$store.state.userData.AstrolabeFriendLists.find(
        (item) => item.Relationship == "R0" && item.IsBelongToMember
      )
      orderObj.AstrolabeFileIds = [selfId];
      orderObj.ReceiverMemberId = null;

      orderObj.Type =
        useType === "gift" ? (giftMode == "memberId" ? 1 : 2) : 0;

      if (orderObj.Type == 1) {
        //錯誤防呆，正常不會觸發
        if (!receiverMemberId)
          return alert("無法取得收禮者資訊，請重新輸入");
        orderObj.ReceiverMemberId = receiverMemberId * 1;
      }
      console.log("orderObj", orderObj)
      this.$store.commit("updateOrderCreater", orderObj);
      this.$router.push("/order/checkInfo");
    },
    mixin_buyAll(useType, giftMode, receiverMemberId, butQuantity) {
      if (!this.$store.state.afterLogin) {
        window.localStorage.setItem("directionPath", this.$route.fullPath);
        return this.$store.commit("setLoginDialogStatus", true);
      }
      if (this._buyAllObj) {

        let orderObj = this.$store.state.orderCreater;
        orderObj.Quantity = butQuantity || 1;
        orderObj.ProductID = this._buyAllObj.ProductID;
        //Astrolabe為自己
        let selfId = this.$store.state.userData.AstrolabeFriendLists.find(
          (item) => item.Relationship == "R0" && item.IsBelongToMember
        );
        orderObj.AstrolabeFileIds = [selfId]
        orderObj.ReceiverMemberId = null;
        orderObj.Type =
          useType === "gift" ? (giftMode == "memberId" ? 1 : 2) : 0;

        if (orderObj.Type == 1) {
          //錯誤防呆，正常不會觸發
          if (!receiverMemberId)
            return alert("無法取得收禮者資訊，請重新輸入");
          orderObj.ReceiverMemberId = receiverMemberId * 1;
        }

        if (orderObj.Type == 0 && this._buyAllObj.isUnlock) {
          return this.$router.push({ name: "Book2024My" });
          // return this.$messagePopup(
          //   "已經解鎖過全部星座囉!",
          //   "您的2024解答之書內所有星座內容都已是解鎖狀態，不用重複進行購買～",
          //   "完成"
          // );
        }

        this.$store.commit("updateOrderCreater", orderObj);
        this.$router.push("/order/checkInfo");
      }
    },
  },
};
