import Base from "@/api/_base";
import store from "@/store";

let { ProductAPI, Encrypt, ErrorHandle, TimeoutHandler } = Base;
let baseConfig = {
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30000,
};

let timer = {};
ProductAPI.interceptors.request.use(
  function (config) {
    if (timer[config.url]) {
      clearTimeout(timer[config.url]);
    }
    timer[config.url] = setTimeout(() => {
      TimeoutHandler(config.url, config.method);
    }, 30000);
    if (config.closeLoadAnimation) {
    } else {
      store.commit("setLoading", true);
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
ProductAPI.interceptors.response.use(
  function (response) {
    clearTimeout(timer[response.config.url]);
    store.commit("setLoading", false);
    return response;
  },
  function (error) {
    // console.log("error", error);
    clearTimeout(timer[error.config.url]);
    store.commit("setLoading", false);
    return Promise.reject(error);
  }
);
export default {
  //-----------------------禮物---------------------

  //禮物-取得-禮物列表資料
  Get_GiftsList: async () => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await ProductAPI.get(`/gifts`, Config);
        return data;
      } catch (err) {
        clearTimeout(timer["/gifts"]);
      }
    }
  },
  //禮物-禮物啟用
  PATCH_AstrolabeFile: async (GiftID, Arg = null) => {
    //Arg:Parameters (若禮物為「宮位解讀」時，則須填寫此欄位)
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await ProductAPI.patch(`/gifts/${GiftId}`, Arg, Config);
        return data;
      } catch (err) {
        clearTimeout(timer["/gifts/{GiftId}"]);
        ErrorHandle("PATCH_AstrolabeFile error", {
          Method: "PATCH",
          Url: "/gifts/{GiftId}",
          Msg: err,
        });
        return false;
      }
    }
  },
  //禮物-禮物購買檢核
  GET_CheckBuyGift: async (ReceiverMemberID, ProductID) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await ProductAPI.get(`/gifts/check?member_id=${ReceiverMemberID}&product_id=${ProductID}`, Config);
    }
  },
  //禮物-兌換碼啟用
  PATCH_ExchangeProductVoucher: async (Code) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await ProductAPI.patch(`/gifts?code=${Code}`, Config);
    }
  },
  //-----------------------商店---------------------
  //取得-商品列表資料*
  GET_Products: async () => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await ProductAPI.get(`/products`, Config);
    }
  },
  //商品明細資料
  GET_ProductDetail: async (ProductID) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await ProductAPI.get(`/products/${ProductID}`, Config);
    }
  },
  //商品交易資料(購買)
  POST_Order: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await ProductAPI.post(`/products/transaction`, Arg, Config);
        return data;
      } catch (err) {
        clearTimeout(timer["/products/transaction"]);
        ErrorHandle("POST_Order error", {
          Method: "post",
          Url: "/products/transaction",
          Msg: err,
        },
          false);
      }
    }
  },



  //-----------------------月亮日記---------------------

  //取得月亮日列表
  GET_MoonDayNoteList: async (Arg) => {
    let Config = JSON.parse(JSON.stringify(baseConfig));
    Config.headers.Authorization = "Bearer " + store.state.token;
    let { Content, PageSize, PageIndex, OrderBy, OrderWay } = Arg;
    return await ProductAPI.get(
      `/moonnotes?content=${Content}&page_size=${PageSize}&page_index=${PageIndex}&order_by=${OrderBy}&order_way=${OrderWay}`,
      Config
    );
  },
  //取得月亮日記資料
  GET_MoonDayNoteDetail: async (MoonNoteID) => {
    let Config = JSON.parse(JSON.stringify(baseConfig));
    Config.headers.Authorization = "Bearer " + store.state.token;
    return await ProductAPI.get(`/moonnotes/${MoonNoteID}`, Config);
  },
  //檢核月亮日記資料
  GET_MoonDayNoteCheck: async ({ Date, Constellation }) => {
    let Config = JSON.parse(JSON.stringify(baseConfig));
    Config.headers.Authorization = "Bearer " + store.state.token;
    return await ProductAPI.get(
      `/moonnotes/check?constellation=${Constellation}&date=${Date}`,
      Config
    );
  },
  //建立月亮日記
  POST_MoonDayNotes: async (MoondayEditFormData) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await ProductAPI.post(`/moonnotes`, MoondayEditFormData, Config);
    }
  },
  //更新月亮日記資料
  PATCH_MoonDayNotes: async (MoonNoteID, MoondayEditFormData) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await ProductAPI.patch(
        `/moonnotes/${MoonNoteID}`,
        MoondayEditFormData,
        Config
      );
    }
  },
  //刪除月亮日記資料
  DELETE_MoonDayNotes: async (MoonNoteID) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await ProductAPI.delete(`/moonnotes/${MoonNoteID}`, Config);
    }
  },
  //下載月亮日記資料文件檔(excel)
  GET_DownloadMoonDay: async ({ StartDate, EndDate }) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      Config.responseType = "blob";
      return await ProductAPI.get(`/moonnotes/file?start_date=${StartDate}&end_date=${EndDate}`, Config);
    }
  },
  //取得月亮日記統計資料
  GET_MoonDayNoteMoodStatistics: async ({ Constellation, Content }) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await ProductAPI.get(
        `/moonnotes/statistics?constellation=${Constellation}&content=${Content}`,
        Config
      );
    }
  },
  //取得月亮日歷明細資料
  GET_MoonDayCalendar: async () => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      // &ShowHourRange=${8}&HourRange=${600}
      return await ProductAPI.get(`/moonnotes/calendar?Type=${0}`, Config);
    }
  },
  //-----------------------運勢之書2024(解答之書)---------------------
  // 取得運勢書列表資料
  GET_BOOKLIST: async () => {
    let Config = JSON.parse(JSON.stringify(baseConfig));
    return await ProductAPI.get(`/books`, Config);
  },

  // 取得運勢書內容資料
  GET_BOOKCONTENT: async (BookID, Constellation) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await ProductAPI.get(
        `/books/${BookID}/content/${Constellation}`,
        Config
      );
    }
  },
  //-----------------------共時曆---------------------
  // 取得運勢書星曆資料
  GET_BOOK_CALENDAR: async (StartDate, EndDate) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await ProductAPI.get(
        `/calendars?start_date=${StartDate}&end_date=${EndDate}`,
        Config
      );
    }
  },

  //-----------------------合盤---------------------
  // 取得合盤列表資料
  GET_SynastrysList: async () => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await ProductAPI.get(
          `/synastrys`,
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/synastrys"]);
      }
    }
  },
  // 取得合盤明細資料
  GET_SynastrysData: async ({ MemberSynastryId }) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await ProductAPI.get(
          `/synastrys/${MemberSynastryId}`,
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/synastrys/SynastryId"]);
      }
    }
  },
};
