<template>
  <div class="navWrap" :class="{ bookStyle: bookStyle }">
    <!-- 電腦版navbar -->
    <nav
      class="nav hidden-laptop-down"
      :class="{ scrollDown: scrollDown, scrollFade: scrollPosition < 60 }"
    >
      <div class="nav__logo" @click="toHome()">
        <img src="../assets/navbar/logo_new.png" alt v-if="!bookStyle" />
        <img src="../assets/運勢書共用/logo.png" alt v-else />
      </div>
      <!-- 上方可以點的地方-->
      <div class="nav__menu">
        <div class="nav__menu__list">
          <!-- 個人星盤 -->
          <div
            class="menu__item mr_24"
            :class="{
              current:
                nowPath.indexOf('/astrolabe') > -1 ||
                $route.query.mode == 'astrolabeMode',
            }"
            @click.stop="toAstrolabe()"
          >
            個人星盤
          </div>

          <!-- 天象星盤 -->
          <div
            class="menu__item mr_24"
            :class="{
              current:
                nowPath.indexOf('/astrometry') > -1 ||
                $route.query.mode == 'astrometryMode',
            }"
            @click.stop="toAstrometry()"
          >
            天象星盤
          </div>

          <!-- 推運星盤 -->
          <div
            class="menu__item mr_24"
            :class="{
              current:
                nowPath.indexOf('/timelineAstrolabe') > -1 ||
                $route.query.mode == 'timeLineMode',
            }"
            @click.stop="toTimelineAstrolabe()"
          >
            推運星盤
          </div>

          <!-- 雙人合盤 -->
          <div
            class="menu__item mr_24"
            :class="{
              current:
                nowPath.indexOf('/astroDBL') > -1 ||
                nowPath.indexOf('/Synastry') > -1 ||
                nowPath.indexOf('/listDBL') > -1,
            }"
            @click.stop="toAstrolabeDBL()"
          >
            雙人合盤
          </div>

          <!-- 各年度解答之書 -->
          <!-- <div
            class="menu__item hoverMenu mr_24"
            @mouseenter="hoverBookList = true"
            @mouseleave="hoverBookList = false"
          >
            各年度解答之書
            <div class="nav__hover bookList" v-show="hoverBookList">
              <div
                class="nav__hover__item"
                :class="{
                  currentHoverMenu: nowPath.indexOf('/book2024') > -1,
                }"
                @click.stop="toBook()"
              >
                <div class="nav__hover__item__pic">
                  <img src="@/assets/navbar/2024運勢書menu圖.png" alt="" />
                </div>
                <div class="nav__hover__item__title">2024<br />解答之書</div>
              </div>

              <div
                class="nav__hover__item"
                :class="{
                  currentHoverMenu: nowPath.indexOf('/book2025') > -1,
                }"
                @click.stop="toBook()"
              >
                <div class="nav__hover__item__pic">
                  <img src="@/assets/navbar/2025運勢書menu圖.png" alt="" />
                </div>
                <div class="nav__hover__item__title">2025<br />解答之書</div>
              </div>
            </div>
          </div> -->

          <!-- 月亮日記 -->
          <div
            class="menu__item mr_24"
            :class="{
              current: nowPath.indexOf('/moonday') > -1,
            }"
            @click.stop="toOther('/moonday-list')"
          >
            月亮日記
          </div>

          <!-- 2024 解答之書 -->
          <div
            class="menu__item mr_24"
            :class="{
              current: nowPath.indexOf('/book2024') > -1,
            }"
            @click.stop="toBook()"
          >
            2024 解答之書
          </div>
          <div
            class="menu__item mr_24"
            :class="{
              current:
                nowPath.indexOf('/friendList') > -1 &&
                nowPath.indexOf('mode') == -1,
            }"
            @click.stop="toOther('/friendList')"
          >
            好友列表
          </div>
          <!-- mr_25K -->
          <div
            class="menu__item"
            :class="{
              current:
                nowPath.indexOf('/store') > -1 ||
                nowPath.indexOf('/order/') > -1,
            }"
            @click.stop="toStore()"
          >
            <img src="../assets/navbar/storeIcon.svg" alt />
            星星雜貨店
          </div>
        </div>
        <!-- <v-spacer></v-spacer> -->
        <div class="nav__login" v-if="!afterLogin" @click="activeLoginBox()">
          登入
        </div>
        <div
          class="isLogin"
          v-if="afterLogin"
          @click.stop="openOtherMenu = !openOtherMenu"
        >
          <div class="photo">
            <v-avatar size="45" class="photo__img">
              <img :src="headImage" />
            </v-avatar>
          </div>
          <div class="other_menu_btn">
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.52344 8L8.44588e-08 0.917434L0.476571 4.8252e-07L11.5235 1.37416e-07L12 0.917434L6.47656 8L5.52344 8Z"
                :fill="bookStyle ? '#fff' : '#D9D9D9'"
              />
            </svg>
          </div>
          <v-slide-y-reverse-transition>
            <div
              class="other_menu_box"
              @click.stop="() => {}"
              v-show="openOtherMenu"
            >
              <div class="userName">{{ userName }}</div>
              <div class="other_menu_segmentation"></div>
              <div
                v-for="item in other_menu_items"
                :key="item.name"
                class="other_menu_item"
                :class="{
                  active: nowPath === item.route,
                }"
                @click.stop="toOther(item.route)"
              >
                {{ item.name }}
              </div>
              <div
                class="other_menu_item active"
                @click.stop="
                  openOtherMenu = false;
                  logoutDialog = true;
                "
              >
                登出
              </div>
            </div>
          </v-slide-y-reverse-transition>
        </div>
      </div>
    </nav>

    <!-- 登入 -->
    <transition name="fade">
      <div
        class="login__overlay"
        :class="{
          scaleBox: loginDialog || LoginOutSide,
          FromLineWebApp: FromLineWebApp,
        }"
        v-if="loginDialog || LoginOutSide"
        @click="loginDialogClose"
      >
        <div class="login__box" @click.stop="loginDialog = true">
          <img class="login__img" src="@/assets/login.svg" alt />
          <div class="login__title">
            <span v-if="loginPlatform == 'all'">
              使用專屬星盤的服務需先登入哦！
              <br />你可以使用以下方式登入
            </span>
            <span v-else>
              請先使用 {{ loginPlatform }} 登入， <br />以方便後續進行帳號歸戶
            </span>
          </div>
          <div class="login__sign">
            <span class="sign__line"></span>
            <span class="sign__text">SIGN IN</span>
            <span class="sign__line"></span>
          </div>
          <div
            class="login__btn line"
            @click.stop="lineLogin"
            v-show="loginPlatform === 'all' || loginPlatform == 'Line'"
          >
            <img src="@/assets/login_line.png" alt />
            使用 LINE 登入
          </div>
          <div
            class="login__btn fb"
            @click.stop="fbLogin"
            v-show="loginPlatform === 'all' || loginPlatform == 'Facebook'"
          >
            <img src="@/assets/login_fb.svg" alt />
            使用 Facebook 登入
          </div>
          <div
            class="login__btn google google-signin-button"
            v-google-signin-button="google.clientId"
            v-show="loginPlatform === 'all' || loginPlatform == 'Google'"
          >
            <img src="@/assets/login_google.svg" alt />
            使用 Google 登入
          </div>
          <div class="login__notice" v-show="loginPlatform == 'all'">
            登入代表您同意唐綺陽專屬星盤
            <br class="hidden-laptop-up" v-if="!FromLineWebApp" />
            <span class="login__privacy" @click.stop="toPrivacyPolicy()">
              <span style="text-decoration: underline">服務</span>及
              <span style="text-decoration: underline">隱私條款</span>
            </span>
          </div>
          <div
            class="login__error"
            @click.stop="openLoginError"
            v-if="FromLineWebApp"
          >
            <span style="text-decoration: underline">無法登入</span>?
            使用瀏覽器開啟
          </div>
        </div>
      </div>
    </transition>
    <!-- 手機Menu -->
    <nav
      class="mobile__navbar"
      :class="{ scrollDown: scrollDown, scrollFade: scrollPosition < 60 }"
    >
      <div class="mobile__container hidden-laptop-up">
        <div class="nav__ham" @click="activeMenu()">
          <img src="@/assets/運勢書共用/icon_menu.svg" v-if="bookStyle" />
          <img src="@/assets/icon_menu.svg" v-else />
        </div>
        <div class="nav__nowPageName">{{ nowPageName }}</div>
        <div
          class="nav__ctaBtn hidden-laptop-up"
          @click="toStore()"
          v-if="afterLogin"
          v-show="!mobileHiddenShopIcon.includes(nowPageRouteName)"
        >
          <img
            src="../assets/運勢書共用/shopping-ctaBtn.svg"
            v-if="bookStyle"
          />
          <img src="../assets/shopping-ctaBtn.svg" v-else />
        </div>
        <div
          class="nav__ctaBtn hidden-laptop-up"
          v-if="afterLogin"
          v-show="mobileHiddenShopIcon.includes(nowPageRouteName)"
        ></div>
        <div class="nav__login" v-else @click="activeLoginBox()">登入</div>
      </div>
    </nav>
    <div class="nav-space"></div>
    <transition name="fade">
      <div
        class="mobile__overlay"
        :class="{ ani: openMenu }"
        v-show="openMenu"
        @click="openMenu = false"
      >
        <div class="mobile" @click.stop="openMenu = true">
          <div class="mobile__close">
            <img
              src="@/assets/icon_menu-close.svg"
              @click.stop="openMenu = false"
              alt
            />
          </div>
          <div class="mobile__wrap">
            <div class="mobile__box">
              <div class="mobile__box__header">
                <div class="mobile__photo" v-if="afterLogin">
                  <v-avatar size="68" class="mobile__photo__img">
                    <img :src="headImage" />
                  </v-avatar>
                  <div class="mobile__photo__platform">
                    <img
                      v-show="loginMethods == 'Line'"
                      src="@/assets/歸戶平台/LINE.png"
                      alt
                    />
                    <img
                      v-show="loginMethods === 'Facebook'"
                      src="@/assets/歸戶平台/facebook.png"
                      alt
                    />
                    <img
                      v-show="loginMethods === 'Google'"
                      src="@/assets/歸戶平台/GOOGLE.png"
                      alt
                    />
                  </div>
                </div>
                <div class="mobile__name">
                  <div class="mobile__name__text">{{ userName }}</div>
                  <div class="mobile__name__btn" @click.stop="toMember()">
                    查看個人檔案
                  </div>
                </div>
              </div>
              <div class="mobile__list">
                <div class="mobile__item mb-0">
                  <img src="@/assets/navbar/star.svg" alt />
                  <div class="mobile__text"><strong>星盤</strong></div>
                </div>
                <div class="mobile__childList">
                  <div
                    class="mobile__childList__childItem"
                    @click.stop="toAstrolabe()"
                    :class="{ current: nowPath.indexOf('/astrolabe') > -1 }"
                  >
                    個人星盤
                  </div>
                  <div
                    class="mobile__childList__childItem"
                    @click.stop="toAstrometry()"
                    :class="{
                      current: nowPath.indexOf('/astrometry') > -1,
                    }"
                  >
                    天象星盤
                  </div>
                  <div
                    class="mobile__childList__childItem"
                    @click.stop="toTimelineAstrolabe()"
                    :class="{
                      current:
                        nowPath.indexOf('/timelineAstrolabe') > -1 ||
                        nowPath.indexOf('timeLineMode') > -1,
                    }"
                  >
                    推運功能
                  </div>

                  <!-- $隱藏 -->
                  <div
                    class="mobile__childList__childItem"
                    @click.stop="toAstrolabeDBL()"
                    :class="{
                      current:
                        nowPath.indexOf('/astroDBL') > -1 ||
                        nowPath.indexOf('/Synastry') > -1,
                    }"
                  >
                    雙人合盤
                  </div>
                </div>

                <!-- $隱藏 -->
                <div
                  class="mobile__item specialEvent"
                  @click.stop="toBook()"
                  :class="{
                    current: nowPath.indexOf('/book2024') > -1,
                  }"
                >
                  <img src="@/assets/navbar/sun.svg" alt="" />
                  <div class="mobile__text">2024 解答之書</div>
                </div>
                <div
                  class="mobile__item"
                  @click.stop="toOther('/moonday-list')"
                  :class="{
                    current: nowPath.indexOf('/moonday') > -1,
                  }"
                >
                  <div class="mobile__text">月亮日記</div>
                </div>
                <div
                  class="mobile__item"
                  @click.stop="toOther('/friendList')"
                  :class="{
                    current:
                      nowPath.indexOf('/friendList') > -1 &&
                      nowPath.indexOf('mode') == -1,
                  }"
                >
                  <div class="mobile__text">好友列表</div>
                </div>
                <div
                  class="mobile__item"
                  :class="{ current: nowPath.indexOf('/store') > -1 }"
                  @click.stop="toStore()"
                >
                  <div class="mobile__text">星星雜貨店</div>
                </div>
                <div class="hr"></div>

                <!-- other ment -->
                <div
                  v-for="item in other_menu_items"
                  :key="item.name"
                  v-show="!item.hiddenMobile"
                  class="mobile__item"
                  @click.stop="toOther(item.route)"
                >
                  <img :src="item.icon" alt />
                  <div class="mobile__text">{{ item.name }}</div>
                </div>
              </div>
              <div
                class="mobile__logout"
                v-if="afterLogin"
                @click.stop="logoutDialog = true"
              >
                <img src="@/assets/icon_menu-logout.svg" alt />
              </div>
            </div>
          </div>
          <img class="mobile__menu__notice" src="@/assets/next.png" />
        </div>
      </div>
    </transition>
    <!-- 登出 -->
    <transition name="fade">
      <div
        class="dialog__overlay"
        v-if="logoutDialog"
        @click="logoutDialog = false"
      >
        <div class="dialog__box" @click.stop="logoutDialog = true">
          <div class="dialog__title">登出帳號</div>
          <div class="dialog__subtitle">確定要登出帳號嗎？</div>
          <div class="dialog__btn">
            <div class="btn cancel" @click.stop="logoutDialog = false">
              取消
            </div>
            <div class="btn confirm" @click.stop="logout">確認</div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 開發中 -->
    <transition name="fade">
      <div
        class="popup__overlay"
        :class="{ scaleBox: devNotice || DevelopOutSide }"
        v-show="developing || DevelopOutSide"
        @click="developingClose"
      >
        <div class="popup__box" @click.stop="developing = true">
          <img class="popup__img" src="@/assets/develop.svg" alt />
          <div class="popup__title">Coming Soon</div>
          <div class="popup__subtitle">服務即將上線！敬請期待！</div>
          <div class="popup__btn" @click.stop="developingClose">返回</div>
        </div>
      </div>
    </transition>
    <LoginErrorDialog v-if="LoginError" @close="closeLoginError()" />
  </div>
</template>

<script>
import LoginErrorDialog from "../components/LoginError";
import GoogleLoginButton from "vue-google-signin-button-directive";

export default {
  name: "",
  props: {
    navlogined: false,
    canNotGo: false,
  },
  components: {
    LoginErrorDialog,
  },
  directives: {
    GoogleLoginButton,
  },
  data() {
    return {
      logoutDialog: false,
      openMenu: false,
      openOtherMenu: false,
      scrollPosition: null,
      devNotice: false,
      developing: false,
      scrollDown: false,
      SendReport: false,
      google: {
        clientId:
          "606143391248-02an5o38e9jjfbkht81uqvq1vfp775qq.apps.googleusercontent.com",
      },
      LoginError: false,
      other_menu_items: [
        {
          name: "個人檔案",
          route: "/member",
          icon: require("../assets/navbar/個人檔案.svg"),
          hiddenMobile: true,
        },
        // $隱藏
        {
          name: "我的解答之書",
          route: "/book2024/my",
          icon: require("../assets/navbar/sun.svg"),
        },
        {
          name: "禮物盒",
          route: "/member/giftBox",
          icon: require("../assets/navbar/sun.svg"),
        },
        {
          name: "購買紀錄",
          route: "/member/record",
          icon: require("../assets/navbar/購買記錄.svg"),
        },
        {
          name: "帳號歸戶",
          route: "/member/importList",
          icon: require("../assets/navbar/帳號歸戶.svg"),
        },
      ],
      loginPlatform: "all",
      hoverAstroList: false,
      hoverBookList: false,
      mobileHiddenShopIcon: ["MoondayNote", "MoondayCreate", "MoondayEdit"],
      devMode: process.env.NODE_ENV == "local" || process.env.NODE_ENV == "dev",
    };
  },
  computed: {
    bookStyle() {
      const bookRegex = /^\/book202[4-7]/;
      return bookRegex.test(this.$route.path);
    },
    LoginOutSide() {
      return this.$store.state.LoginOutSide;
    },
    DevelopOutSide() {
      return this.$store.state.developing;
    },
    FromLineWebApp() {
      return this.$store.state.FromLineWebApp;
    },
    headImage() {
      return this.$store.state.headImage;
    },
    afterLogin() {
      return this.$store.state.afterLogin;
    },
    userName() {
      if (this.$store.state.userData) {
        let self = this.$store.getters.SELF_OBJ;
        return self.Name;
      }
      return "";
    },
    nowPath() {
      return this.$route.fullPath;
    },
    nowPageName() {
      return this.$route.meta.pageName || "";
    },
    nowPageRouteName() {
      return this.$route.name || "";
    },

    loginMethods() {
      return localStorage.loginMethods;
    },
    loginDialog: {
      get() {
        return this.$store.state.loginDialogStatus;
      },
      set(val) {
        this.$store.commit("setLoginDialogStatus", val);
      },
    },
    canUserNav() {
      return this.$route.name != "member-create";
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    window.document.addEventListener("click", () => {
      this.openOtherMenu = false;
    });
    if (this.$route.query.login) {
      this.loginDialog = true;
      // alert(this.$route.query.login);
      switch (this.$route.query.login) {
        case "Line":
          return (this.loginPlatform = "Line");
        case "Facebook":
          return (this.loginPlatform = "Facebook");
        case "Google":
          return (this.loginPlatform = "Google");
      }
    }
  },
  methods: {
    //FB登入
    fbLogin() {
      window.fbAsyncInit();
      this.$store.state.LoginOutSide = false;
      let fb_response;
      FB.login(
        (response) => {
          fb_response = response;
          if (fb_response.authResponse) {
            this.getProfile();
          } else {
            this.loginDialog = false;
            this.$router.push({ path: "/" });
          }
        },
        {
          scope: "email, public_profile",
          return_scopes: true,
        }
      );
    },
    getProfile() {
      FB.api("/me", { locale: "en_US", fields: "name, email" }, (response) => {
        if (process.env.NODE_ENV == "dev") {
          // alert("測試FB登入");
        }
        let imgUrl =
          "https://graph.facebook.com/" + response.id + "/picture?type=square";
        let AC;
        if (response.email != undefined) {
          AC = this.$API.Encrypt(response.email);
          // window.localStorage.setItem("before", response.email);
        } else if (response.id) {
          AC = this.$API.Encrypt(response.id);
          // window.localStorage.setItem("before", response.id);
        } else {
          alert("faceback ID 取得失敗，請選擇其他方式登入");
        }
        window.localStorage.setItem("email", response.email);
        window.localStorage.setItem("loginMethods", "Facebook");
        window.localStorage.setItem("AC", AC);
        window.localStorage.setItem("imageUrl", imgUrl);

        setTimeout(() => {
          window.location =
            this.$store.state.app_return_url +
            (this.loginPlatform === "all" ? "" : "?state=isImport");
        }, 1000);
      });
    }, //google登入
    OnGoogleAuthSuccess(idToken) {
      // 取得token
      this.$store.state.LoginOutSide = false;
      this.$API.Get_Google_Info(idToken).then((response) => {
        let AC = this.$API.Encrypt(response.Data.email);
        window.localStorage.setItem("before", response.Data.email);
        window.localStorage.setItem("AC", AC);
        window.localStorage.setItem("email", response.Data.email);
        window.localStorage.setItem("imageUrl", response.Data.picture);
        window.localStorage.setItem("loginMethods", "Google");

        setTimeout(() => {
          window.location =
            this.$store.state.app_return_url + this.loginPlatform === "all"
              ? ""
              : "?state=isImport";
        }, 1000);
      });
    }, //Line登入
    lineLogin() {
      this.$store.state.LoginOutSide = false;
      var url = "https://access.line.me/oauth2/v2.1/authorize?";
      url += "response_type=code";
      url += "&client_id=" + process.env.VUE_APP_client_id; //正式
      url += "&redirect_uri=" + this.$store.state.app_return_url; //正式
      url += `&state=afterLineBack${
        this.loginPlatform === "all" ? "" : ",isImport"
      }`;
      url += "&scope=openid%20profile";
      // %20email
      window.location = url;
    },
    loginDialogClose() {
      this.loginDialog = false;
      this.$store.state.LoginOutSide = false;
    },
    developingClose() {
      this.developing = false;
      this.$store.state.developing = false;
    },
    openLoginError() {
      this.LoginError = true;
      setTimeout(() => {
        this.loginDialog = false;
      }, 1000);
    },
    closeLoginError() {
      this.LoginError = false;
      this.$router.push("/").catch((err) => {});
    },

    OnGoogleAuthFail(error) {
      this.$router.go();
    },

    //navbar change on scroll

    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    activeLoginBox() {
      window.localStorage.setItem("directionPath", this.$route.fullPath);
      this.loginDialog = true;
    },
    //隱私條款
    toPrivacyPolicy() {
      this.loginDialog = false;
      this.$router.push({ path: "/privacy-policy" });
    },
    activeMenu() {
      this.openMenu = true;
    },
    toAstrolabe() {
      if (this.canUserNav) {
        this.$router.push({ path: "/astrolabe" });
      } else {
        alert("請先填寫資料");
      }
    },
    toAstrolabeDBL() {
      // return this.develop();
      if (process.env.NODE_ENV == "production") {
        return this.develop();
      }
      if (!window.localStorage.getItem("login_status")) {
        this.loginDialog = true;
        return;
      }
      if (!this.canUserNav) {
        return alert("請先填寫資料");
      }
      let friendList = this.$store.state.userData.AstrolabeFriendLists;
      if (friendList.length < 2) {
        return this.$messagePopup(
          "請先新增好友資料",
          "您還未有好友資料，請先新增好友，</br>以方便進行合盤設定。",
          "前往新增",
          () => this.$router.push("/friend-create?back=/Synastry")
        );
      }
      let ListDBL = this.$store.getters.ListDBL;
      if (!ListDBL || !ListDBL.length > 0) {
        return this.$router.replace("/Synastry");
      }
      this.$router.push({ path: "/listDBL" });
    },
    toTimelineAstrolabe() {
      if (this.canUserNav) {
        this.$router.push("/timelineAstrolabe");
      } else {
        alert("請先填寫資料");
      }
    },
    toAstrometry() {
      if (this.canUserNav) {
        this.$router.push("/astrometry");
      } else {
        alert("請先填寫資料");
      }
    },
    toBook() {
      if (this.canUserNav) {
        this.$router.push("/book2024");
      } else {
        alert("請先填寫資料");
      }
    },
    toStore() {
      if (this.canUserNav) {
        this.$router.push({ path: "/store" });
      } else {
        alert("請先填寫資料");
      }
    },
    toOther(router) {
      if (this.canUserNav) {
        this.$router.push({ path: router });
        this.openMenu = false;
      } else {
        alert("請先填寫資料");
      }
    },
    toMember() {
      if (this.canUserNav) {
        this.$router.push({ path: "/member" });
      } else {
        alert("請先填寫資料");
      }
    },
    toHome() {
      this.$router.push("/");
    },
    logout() {
      window.localStorage.removeItem("login_status");
      window.localStorage.removeItem("AC");
      window.localStorage.removeItem("token");
      window.localStorage.clear();
      setTimeout(() => {
        window.location =
          this.$route.meta.logoutDirectIndexPage || window.location.origin;
      }, 200);
    },
    develop() {
      this.developing = true;
    },
    toReport() {
      this.$router.push({ path: "/report" });
    },
    mobileSettingMenuOpen(handler) {
      if (handler == "back") {
        this.openOtherMenu = false;
        this.openMenu = true;
      }
    },
  },
  watch: {
    //滾動監聽
    scrollPosition(newValue, oldValue) {
      if (newValue < 30) {
        this.scrollDown = false;
      } else if (newValue - oldValue > 0) {
        this.scrollDown = true;
      } else {
        this.scrollDown = false;
      }
    },
    nowPath() {
      this.openMenu = false;
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
@import "@/scss/dialog.scss";
@import "@/scss/popup.scss";

.nav {
  position: fixed;
  z-index: 100;
  width: 100%;
  background: #f0f2f4;
  box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.16), -4px -4px 10px #ffffff,
    -4px -4px 16px #ffffff;
  padding: 0 72px 0 88px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 95px;
  transition: transform 0.4s;
  &__logo {
    height: 57px;
    cursor: pointer;
    img {
      height: 100%;
      object-fit: cover;
    }
  }
  &__menu {
    @include align(center, center);
    flex-grow: 1;
    .nav__menu__list {
      @include align(center, center);
      flex-grow: 1;
      .menu__item {
        position: relative;
        font-size: 20px;
        line-height: 33px;
        color: #868686;
        transition: 0.3s;
        font-family: "Noto Sans TC", "Microsoft JhengHei", Helvetica;
        cursor: pointer;
        &:not(.hoverMenu) img {
          width: 20px;
          position: absolute;
          top: -8px;
          right: -12px;
        }
        &:not(.hoverMenu):hover,
        .nav__hover__item__title:hover {
          color: $theme-color-1;
        }

        &.current {
          font-weight: bold;
          color: #17445c;

          &::before {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -9px;
            transform: translateX(-50%);
            width: 40px;
            height: 4px;
            border-radius: 2px;
            box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.15),
              2px 2px 4px 0 #ffffff;
            background-color: $theme-color-3;
          }
        }
        .currentHoverMenu {
          font-weight: bold;
          color: #17445c !important;
        }
      }
    }

    .isLogin {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      .photo {
        margin-right: 16px;
        width: 56px;
        height: 56px;
        @include center;
        background-image: url("../assets/avatar_bg.svg");
        background-repeat: no-repeat;
        background-size: cover;

        .photo__img {
          border: solid 0.8px #ab956c;
        }
      }

      .other_menu_btn {
        cursor: pointer;
      }
      .other_menu_box {
        @include rect(161px, auto, $theme-color-3, 20px);
        box-shadow: -4px -4px 12px #ffffff, -2px -2px 8px #ffffff,
          4px 4px 20px rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 54px;
        right: 0;
        overflow: hidden;
        transition: 0.3s;
        padding: 5px 0;
        .userName {
          width: 100%;
          height: 32px;
          font-size: 20px;
          font-weight: 700;
          color: $secondary-color-1;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 0 12px;
          text-align: center;
        }
        .other_menu_segmentation {
          width: 40px;
          height: 4px;
          background: #f0f2f4;
          box-shadow: 2px 2px 4px #ffffff, inset 2px 2px 4px rgba(0, 0, 0, 0.15);
          border-radius: 2px;
          margin: 6px auto 10px;
        }
        .other_menu_item {
          width: 100%;
          height: 32px;
          @include center;
          font-size: 18px;
          font-weight: 500;
          line-height: 1.25;
          letter-spacing: 1.28px;
          color: $secondary-color-3;
          background-color: $theme-color-3;
          cursor: pointer;
          font-weight: 700;
          &:hover {
            background-color: $special_bg_color2;
          }

          &.active {
            color: $theme-color-1;
            font-weight: bold;
          }
          &.mobileHidden {
            @include laptop {
              display: none;
            }
          }
        }
      }
    }
  }
  //滑動隱藏機制相關
  &.scrollDown {
    //特殊情況(大於60，且往上滑的時候)會上捲
    transform: translateY(-100%);
  }
  /* &.scrollFade {
    background-color: rgba($color: $secondary-color-2, $alpha: 0.2);
    @include laptop {
      background-color: transparent;
    }
  } */
}

.nav-space {
  width: 100%;
  height: 95px;
  @include laptop {
    height: 60px;
  }
}

//登入
.login__overlay {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: rgba(240, 242, 244, 0.4);
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.5s;
  @include center;
  &.scaleBox .login__box {
    animation: scaleBox 0.25s ease-in-out forwards;
  }
  &.FromLineWebApp {
    background-color: rgba(240, 242, 244, 1);
  }

  @keyframes scaleBox {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }

  .login__box {
    z-index: 1050;
    position: fixed;
    margin: auto;
    @include rect(628px, auto, $theme-color-3, 100px);
    @include center;
    flex-direction: column;
    padding: 32px 55px;
    transition: 0.5s;

    .login__img {
      width: 64px;
      height: 64px;
    }

    .login__title {
      @include text-1;
      text-align: center;
      margin-top: 16px;
    }

    .login__sign {
      @include align(space-around, center);
      margin: 32px 0;
      width: 100%;
      @include laptop() {
        margin: 24px 0 16px;
      }

      .sign__line {
        // width: 192px;
        flex-grow: 1; //
        height: 1px;
        background-color: $theme-color-2;
      }

      .sign__text {
        font-family: "Yeseva One", cursive;
        font-size: 18px;
        line-height: 1.22;
        letter-spacing: 1.44px;
        color: $theme-color-2;
        margin: 0 22px;
        @include laptop {
          font-size: 14px;
          line-height: 1.57;
          letter-spacing: 1.12px;
        }
      }
    }

    .login__btn {
      // @include btn-default(374px, 56px, 18px);
      position: relative;
      @include center;
      cursor: pointer;
      width: 374px;
      height: 56px;
      font-size: 18px;
      margin-bottom: 16px;
      img {
        position: absolute;
        left: 20px;
      }
      &.line {
        background-color: #00c300;
        color: #fff;
        img {
          width: 40px;
          height: 40px;
          left: 10px;
          @include laptop() {
            width: 32px;
            height: 32px;
          }
        }
      }
      &.fb {
        background-color: #4267b2;
        color: #fff;
        img {
          width: 30px;
          height: 30px;
          @include laptop() {
            width: 22px;
            height: 22px;
          }
        }
      }
      &.google {
        background-color: #fff;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
        color: #757575;
        img {
          width: 24px;
          height: 24px;
          @include laptop() {
            width: 18px;
            height: 18px;
          }
        }
      }
      @include laptop() {
        // @include btn-default(90%, 40px, 14px);
        width: 90%;
        height: 40px;
        font-size: 14px;
        img {
          position: absolute;
          left: 12px;
        }
      }
    }

    .login__notice {
      margin-top: 16px;
      font-size: 16px;
      line-height: 1.63;
      letter-spacing: 1.28px;
      color: $secondary-color-3;
      @include laptop() {
        margin-top: 24px;
        font-size: 10px;
        letter-spacing: 0.8px;
        text-align: center;
        white-space: nowrap;
      }
    }

    .login__privacy {
      cursor: pointer;
    }
    .login__error {
      margin-top: 10px;
      margin-bottom: 18px;
      font-size: 12px;
      letter-spacing: 0.48px;
      text-align: center;
      color: #ab956c;
    }

    @include laptop() {
      @include rect(90%, auto, $theme-color-3, 110px);
    }
    @include laptop() {
      padding: 40px 32px;
    }
  }
}

//手機板
.mobile__navbar {
  position: fixed;
  z-index: 100;
  width: 100%;
  transition: transform 0.4s, background-color 0.6s;
  background: #f0f2f4;
  // box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.13), -2px -2px 8px #ffffff,
  //   -2px -2px 8px #ffffff;
  &.scrollDown {
    //特殊情況(大於60，且往上滑的時候)會上捲
    transform: translateY(-100%);
  }
  &.scrollFade {
    background-color: rgba($color: $secondary-color-2, $alpha: 0.2);
    @include laptop {
      background-color: transparent;
    }
  }
}
.mobile__container {
  height: 60px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .nav__ham {
    cursor: pointer;
    width: 50px;
    height: 50px;

    img {
      width: 100%;
      height: 100%;
    }

    @include laptop {
      width: 40px;
      height: 40px;
    }
  }
  .nav__nowPageName {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.016em;

    color: #ab956c;
  }
  .nav__ctaBtn {
    width: 24px;
    height: 24px;
    background-color: transparent;
    img {
      width: 100%;
    }
  }
}
.nav__login {
  @include btn-default(86px, 38px, 20px);
  @include laptop {
    @include btn-default(72px, 32px, 14px);
  }
}
.mobile__overlay {
  @include overlay;
  @include align(flex-start, flex-start);
  &.ani .mobile {
    animation: toRight 0.3s ease-in-out forwards;
  }
  .mobile {
    position: relative;
    padding-right: 4px;
    transform: translateX(-100%);
    width: 75%;
    height: 100%;
    opacity: 0.95;
    box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.2), -2px -2px 8px 0 #ffffff,
      -4px -4px 12px 0 #ffffff;
    background-color: #798da5;
    transition: 0.5s;
    .mobile__close {
      position: fixed;
      top: 28px;
      left: calc(75vw + 28px);
      z-index: 999;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
    .mobile__menu__notice {
      position: fixed;
      bottom: 20px;
      left: 200px;
      animation: fade 2s linear;
      animation-fill-mode: forwards;
      visibility: hidden;
      @media screen and (max-height: 575px) {
        visibility: visible !important;
      }
    }
    &__wrap {
      height: 100%;
      overflow: auto;
      padding: 36px;
      &::-webkit-scrollbar {
        width: 4px;
        opacity: 0.35;
        margin-right: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #d9d9d9;
        border-radius: 30px;
      }
      .mobile__box {
        position: relative;
        &__header {
          width: 100%;
          display: flex;
          align-items: center;
          padding-bottom: 36px;
          border-bottom: 0.8px solid #d9d9d9;
          .mobile__photo {
            position: relative;
            &__platform {
              position: absolute;
              right: -6px;
              bottom: -2px;
              width: 24px;
              height: 24px;
              border-radius: 50%;
              img {
                width: 100%;
              }
            }
          }
          .mobile__name {
            margin-left: 20px;
            &__text {
              font-size: 18px;
              line-height: 27px;
              color: #f0f2f4;
              font-weight: 700;
            }
            &__btn {
              margin-top: 2px;
              color: rgba(#f0f2f4, 0.8);
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 0.7px;
            }
          }
        }

        .mobile__list {
          padding-top: 36px;
          .hr {
            width: 100%;
            margin: 8px 0 36px;
            height: 1px;
            background-color: rgba(255, 255, 255, 0.35);
          }
          .mobile__item {
            margin-bottom: 28px;
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
            img {
              margin-right: 12px;
            }

            .mobile__text {
              font-size: 16px;
              font-weight: 500;
              color: $secondary-color-2;
              line-height: 1;
              strong {
                font-weight: 700;
              }
            }

            &.current {
              .mobile__text {
                letter-spacing: 2.24px;
                font-size: 20px;
                font-weight: bold;
                &.sm {
                  font-size: 20px;
                  color: #f0f2f4;
                }
              }

              .mobile__eng {
                display: block;
              }
            }
            &.specialEvent {
              position: relative;
              left: -12px;
              padding-left: 12px;
              height: 32px;
              margin-bottom: 25px;
              border-radius: 3px;
              background: linear-gradient(
                120deg,
                #69a4fd 33.96%,
                #2decf9 154.22%
              );
            }
          }
          .mobile__childList {
            margin-left: 12px;
            margin-top: 16px;
            margin-bottom: 16px;
            padding: 8px 0;
            border-left: 1px solid rgba(#d9d9d9, 0.35);
            &__childItem {
              margin-left: -1px;
              padding-left: 20px;
              height: 32px;
              font-size: 16px;
              font-weight: 500;
              line-height: 32px;
              letter-spacing: 1.28px;
              color: #f0f2f4;
              &:not(:last-of-type) {
                margin-bottom: 20px;
              }
              &.current {
                background-color: rgba(#d9d9d9, 0.25);
                border-left: 2px solid #fff;
              }
            }
          }
        }
      }
    }

    .mobile__logout {
      cursor: pointer;
      position: absolute;
      bottom: -40px;
      right: 0;
    }
  }
  .mobile.setting {
    width: 188px;
    background: #f0f2f4;
    box-shadow: -4px -4px 12px #ffffff, -2px -2px 8px #ffffff,
      4px 4px 20px rgba(0, 0, 0, 0.2);

    .other_menu {
      margin-top: 30px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .userName {
        font-size: 20px;
        line-height: 36px;
        color: #ab956c;
        font-weight: 700;
        width: 160px;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .other_menu_segmentation {
        width: 40px;
        height: 4px;
        background: #f0f2f4;
        box-shadow: 2px 2px 4px #ffffff, inset 2px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 2px;
        margin: 5px auto 20px;
      }
      .other_menu_item {
        font-size: 20px;
        line-height: 30px;
        color: #798da5;
        margin-bottom: 20px;
        font-weight: 700;
        &.active {
          color: #17445c;
          font-weight: 900;
        }
        &.mobileHidden {
          @include laptop {
            display: none;
          }
        }
      }
    }
  }

  @keyframes toRight {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

// ----- 運勢書模式的style-------
.bookStyle {
  .nav {
    background: #214c73;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.2);
    transition: 0s;
    &__menu {
      .nav__menu__list {
        .menu__item {
          color: #fff;
          transition: 0s;

          &:not(.hoverMenu):hover,
          .nav__hover__item__title:hover {
            color: #fff;
          }

          &.current {
            font-weight: bold;
            color: #fff;

            &::before {
              box-shadow: none;
              background-color: #fff;
            }
          }
          &.currentHoverMenu {
            font-weight: bold;
            color: #fff !important;
          }
        }
        .nav__hover__item__title {
          color: #fff;
        }
      }

      .isLogin {
        .photo {
          background-image: url("../assets/運勢書共用/avator_bg.png");
          .photo__img {
            border: solid 0.8px #fff;
          }
        }

        .other_menu_btn {
          cursor: pointer;
        }
        .other_menu_box {
          @include rect(161px, auto, #214c73, 20px);
          box-shadow: -2px -2px 8px 0px #fff;
          .userName {
            color: #fff;
          }
          .other_menu_segmentation {
            background: #fff;
            box-shadow: none;
          }
          .other_menu_item {
            color: #fff;
            background-color: #214c73;
            &:hover {
              background-color: #fff;
              color: #214c73;
            }
          }
        }
      }
    }
    //滑動隱藏機制相關
    &.scrollDown {
      //特殊情況(大於60，且往上滑的時候)會上捲
      transform: none;
    }
  }
  .mobile__navbar {
    transition: 0s;
    background: #0c2f58;
    &.scrollDown {
      transform: none;
    }
    &.scrollFade {
      background-color: transparent;
    }
    .nav__nowPageName {
      color: #fff;
    }
  }
  .mobile__overlay {
    .mobile {
      // background-color: #214c73;
      transition: 0s;
    }
  }
  .nav__login {
    background-color: transparent;
    border: 1px solid #fff;
    &:hover {
      background-color: #fff;
      color: #214c73;
    }
  }
  .nav__hover {
    background: #214c73;
    filter: drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.2));
    &::before {
      border-bottom: 20px solid #214c73;
    }
    .nav__hover__item__title {
      color: #fff;
    }
  }
}

// hover 才出現的選單列表 電腦版
.nav__hover {
  position: absolute;
  top: 60px;
  background-color: #f0f2f4;
  height: 238px;
  border-radius: 24px;
  display: flex;
  gap: 56px;
  padding: 36px 48px 40px;
  filter: drop-shadow(-2px -2px 4px #ffffff) drop-shadow(-2px -2px 4px #ffffff)
    drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.2));
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 20px solid #f0f2f4;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    z-index: 1;
  }
  &:after {
    content: "";
    position: absolute;
    width: 120px;
    height: 30px;
    background-color: transparent;
    transform: translate(-40px, -12px);
  }
  &.astroList {
    left: -60px;
    &::before,
    &::after {
      content: "";
      top: -18px;
      left: 90px;
    }
  }
  &.bookList {
    left: 50%;
    transform: translateX(-50%);
    &::before {
      content: "";
      top: -18px;
      left: 50%;
      transform: translateX(-50%);
    }
    &::after {
      content: "";
      top: -18px;
      left: 50%;
      transform: translate(-50%, -12px);
    }
  }

  &__item {
    width: 128px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #868686;
    font-weight: 400;
    &__pic {
      height: 128px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    &__title {
      font-size: 18px;
      margin-top: 8px;
      line-height: 1.2;
      text-align: center;
    }
  }
}
</style>
