import Base from "@/api/_base";
import store from "@/store";

let { GatewayAPI, Encrypt, ErrorHandle, TimeoutHandler } = Base;
let baseConfig = {
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30000,
};
let timer = {};
GatewayAPI.interceptors.request.use(
  function (config) {
    if (timer[config.url]) {
      clearTimeout(timer[config.url]);
    }
    timer[config.url] = setTimeout(() => {
      TimeoutHandler(config.url, config.method);
    }, 30000);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
GatewayAPI.interceptors.response.use(
  function (response) {
    clearTimeout(timer[response.config.url]);
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export default {
  //----------------基本------------------
  //發送問題回報通知信件
  SendReport: async (Arg) => {
    let Config = JSON.parse(JSON.stringify(baseConfig));
    Config.headers["Content-Type"] = "multipart/form-data";
    try {
      let { data } = await GatewayAPI.post(
        `/mails/problem`,
        Arg,
        Config
      );
      return data;
    } catch (err) {
      clearTimeout(timer["/mails/problem"]);
      ErrorHandle("SendReport error", {
        Method: "post",
        Url: "/mails/problem",
        Msg: err,
      });
    }
  },
  //聯絡我們通知信發送
  POST_ContactUsMail: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await GatewayAPI.post(
          `/mails/contact`,
          Arg,
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/mails/contact"]);
        ErrorHandle("POST_ContactUsMail error", {
          Method: "post",
          Url: "/mails/contact",
          Msg: err,
        });
      }
    }
  },
  //發送-會員驗證碼通知信
  POST_MemberCaptchaMail: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await GatewayAPI.post(
          `/mails/member_captcha`,
          Arg,
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/mails/member_captcha"]);
        ErrorHandle("POST_MemberCaptchaMail error", {
          Method: "post",
          Url: "/mails/member_captcha",
          Msg: err,
        });
      }
    }
  },
};
